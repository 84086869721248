<template>
  <div>
    <el-table :data="tableData" border :span-method="arraySpanMethod" style="width: 100%">
      <el-table-column v-for="(item, index) in tablehead" :key="item" :render-header="addRedStar"
        :label="labeltxt(item)" align="center" :width="item == 'ship_regions' ? '300px' : ''">
        <template slot-scope="scope">
          <!-- 规格 -->
          <span v-if="checkFixed(item)">
            <span v-if="item === 'price'"> {{ scope.row[item] | unitPrice('￥') }}</span>
            <span v-else> {{ scope.row[item] }}</span>
          </span>
          <!-- 价格 -->
          <div v-else class="input-error-model">
            <div v-if="item !== 'ship_regions'">
              <el-input :maxlength="30" v-model.trim="scope.row[item]" @blur="updateSkuTable(index, scope, item)">
              </el-input>
              <span class="input-error" v-if="item !== 'sn'" v-show="isValidate(index, scope)">{{
      scope.column.label == '库存' ? '请输入0~99999999之间的整数' : validatatxt }}
              </span>
            </div>
            <div style="display: flex;align-items: center;" v-else>
              <el-tooltip class="item" effect="light" placement="top-end">
                <p slot="content" v-html="scope.row[item]"></p>
                <p class="displayBoard" v-html="scope.row[item]"></p>
              </el-tooltip>
              <el-button type="primary" size="small" @click="setDistributionArea(scope.row)">{{ scope.row[item] ?
      '修改配送区域' : '配送区域设置' }}</el-button>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- <p v-if="isEditModel === 1" class="stock-tip"> 提示: 编辑商品规格时不允许进行库存编辑，请到商品列表单独维护库存 </p> -->
    <div class="batch-all" v-show="tablehead && tablehead.length">
      <span>批量设置：</span>
      <div v-show="isShowBatch">
        <el-button type="text" size="mini" @click="setBatch(1)">{{ tablehead.some(keys => keys == 'price') ? '成本价' :
      '供货价'
          }}</el-button>
        <el-button type="text" size="mini" @click="setBatch(2)">市场价</el-button>
        <el-button v-if="tablehead.some(keys => keys == 'price')" type="text" size="mini"
          @click="setBatch(3)">会员价</el-button>
        <el-button v-else type="text" size="mini" @click="setBatch(4)">集采价</el-button>
        <el-button type="text" size="mini" @click="setBatch(5)">库存</el-button>
        <el-button type="text" size="mini" @click="setAddress">配送区域</el-button>
      </div>
      <div v-show="!isShowBatch">
        <el-input v-model.number.trim="batch" @change="changeBatch" size="mini" style="width: 100px;"></el-input>
        <el-button type="text" size="mini" @click="saveBatch">保存</el-button>
        <el-button type="text" size="mini" @click="cancelBatch">取消</el-button>
      </div>
    </div>
    <x-dialog :proxy="selectDeliveryArea">
      <div style="position: relative;">
        <div class="el-tree-box">
          <el-tree :empty-text="index === 1 ? '暂无数据' : ''" v-for="(item, index) in areaData" :key="index"
            style="width:30%" :data="item" :accordion="true" show-checkbox :props="defaultProps"
            :render-content="renderContent" node-key="id" ref="tree" @check-change="handleCheckChange"></el-tree>
        </div>
      </div>
      <template slot="footer-left">
        <div class="el-tree-bottom">
          <el-checkbox v-model="checkedAll" @change="setCheckedNodes">全选</el-checkbox>
          <div class="el-tree-bottom">
            <div style="color:#bbbbbb;margin-right:10px;">已选择{{ checkedNodesNum }}个区域</div>
            <el-button :disabled="checkedNodesNum === 0" type="primary" size="small"
              @click="submitDeliveryArea">提交</el-button>
          </div>
        </div>
      </template>
    </x-dialog>
  </div>
</template>

<script>
import { RegExp } from '@/../ui-utils'
import * as API_express from "@/api/expressMould";
import xDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
export default {
  name: 'skuTable',
  components: {
    xDialog,
  },
  props: {
    /** 当前模式 发布商品0 编辑商品1 编辑草稿箱商品2 */
    isEditModel: {
      type: Number,
      default: 1
    },

    /** 是否自动生成货号 */
    productSn: {
      type: Boolean,
      default: false
    },

    /** 当前商品编号 */
    goodsSn: {
      type: [String, Number],
      default: ['', 0]
    },

    /** 列表信息 */
    skuInfo: {
      type: Array,
      default: [{
        quantity: 0,
        cost: '',
        price: '',
        // 所有配送地区id
        ship_regions_ids: "",
        ship_regions: "",
      }]
    },

    /** 表头信息 */
    tablehead: {
      type: Array,
      default: []
    }
  },
  watch: {
    skuInfo () {
      this.tableData = this.skuInfo
      console.log(this.tableData, 'this.tableData');
      this.concactArray = []
      this.tableData.forEach((key, index) => {
        this.concactArrayCom(index, key)
      })
    },

    /** 自动生成货号 */
    productSn () {
      if (this.productSn && this.tableData.length > 0) {
        let count = 1
        this.tableData.forEach(key => {
          key.sn = this.goodsSn + '-00000' + count
          count++
        })
        /** 异步更新skuInfo数据 */
        this.$emit('skuTable', this.tableData)
      }
    }
  },
  mounted () {
    this.tableData = this.skuInfo
    this.tableData.forEach((key, index) => {
      this.concactArrayCom(index, key)
    })
    // 获取区域配送数据
    API_express.getAreaList().then(response => {
      const num = response.length;
      this.totalData = [...response];
      this.areaData[0] = response.splice(0, Math.ceil(num / 3));
      this.areaData[1] = response.splice(0, Math.ceil(num / 3));
      this.areaData[2] = response.splice(0);
      this.$forceUpdate();
    });
  },
  data () {
    return {
      /** 是否显示批量设置的值 */
      isShowBatch: true,

      /** 批量设置的值 */
      batch: '',

      /** 当前操作的值 1价格 2库存 */
      activeVal: 0,

      /** 列表数据 */
      tableData: this.skuInfo,

      /** 要合并的列的位置数组 */
      concactArray: [],

      /** 固定列校验提示内容 */
      validatatxt: '请输入0.01~9999999.99之间的正数',

      /** 存储未通过校验的单元格位置  */
      validateError: [],
      /**要修改配送区域的下标 */
      areaAddress: '',
      // 配送区域弹窗开关
      selectDeliveryArea: $xDialog.create({
        title: "选择配送区域",
        width: "50vw",
        disableCancel: true,
        disabled: true
      }),
      // 省级地区列表
      areaData: [[], [], []],
      // 树形地区选择器参数配置
      defaultProps: {
        label: "local_name",
        children: "children"
      },
      // 选中一级数
      checkedNodesSum: 0,
      // 选中三级数
      checkedNodesNum: 0,
      // 选中数组
      checkedNodesList: [],
      // 总数组
      totalData: [],
      // 全选开关
      checkedAll: false,
      special1: [],
      special2: []
    }
  },
  methods: {
    addRedStar (h, { column }) {//表头添加*号
      let _output = [];
      if (['供货价', '市场价', '集采价', '库存', '会员价', '配送区域'].includes(column.label)) {
        _output = [
          h('span', { style: 'color: red' }, '*'),
          h('span', ' ' + column.label)
        ]
      } else {
        _output = [h('span', ' ' + column.label)]
      }
      return _output
    },
    /** 固定表头文本格式化 */
    labeltxt (item) {
      let _output = ''
      switch (item) {
        case 'sn': _output = '货号'; break
        case 'weight': _output = '重量(kg)'; break
        case 'cost':
          this.tablehead.some(keys => keys == 'price') ? _output = '成本价' : _output = '供货价'
            ;
          break
        case 'mktprice': _output = '市场价'; break
        case 'original_price': _output = '集采价'; break
        case 'price': _output = '会员价'; break
        case 'quantity': _output = '库存'; break
        case 'ship_regions': _output = '配送区域'; break
        default: _output = item
      }
      return _output
    },

    /** 当前表头是否属于固定表头的鉴定 */
    checkFixed (item) {
      if (item === 'quantity' || item === 'cost' || item === 'price' || item === 'mktprice' || item === 'original_price' || item === 'ship_regions') {
        return false
      }
      return true
    },

    /** 合并数据相同的单元格 */
    arraySpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex < this.tablehead.length - 5) {
        const _row = this.concactArray[rowIndex][columnIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },

    /** 计算要合并列的位置 */
    concactArrayCom (index, item) {
      let _isMerge = false
      /** 循环列 先循环第一列 若相同则合并 再循环第二列 依次循环 若不相同 则不合并并终止此列循环开始下一列循环 */
      let _currnetRow = []
      for (let i = 0, _len = this.tablehead.length - 5; i < _len; i++) {
        if (this.tablehead[i] === 'spec_value_id') {
          i++
          continue
        }
        if (index > 0 && item[this.tablehead[i]] !== this.skuInfo[index - 1][this.tablehead[i]]) {
          _currnetRow[i] = 1
          _isMerge = true
        } else if (index > 0 && !_isMerge) {
          _currnetRow[i] = 0
          let _count = 1
          while (this.concactArray[index - _count][i] === 0) {
            _count++
          }
          this.concactArray[index - _count][i] += 1
        } else { // index === 0
          _currnetRow[i] = 1
        }
      }
      this.concactArray.push(_currnetRow)
    },

    /** 检测是否未通过0-99999999之间的数字校验 */
    isValidate (index, scope) {

      return this.validateError.some(key => {
        return key[0] === index && key[1] === scope.$index
      })
    },
    // 配送区域设置
    setDistributionArea (row) {
      this.areaAddress = row;
      this.selectDeliveryArea.display();
      const area_index = this.tableData.findIndex(item => item.spec_value_id === row.spec_value_id);
      if (this.tableData[area_index].ship_regions_ids.length === 0) return;
      // 渲染配送区域数据
      setTimeout(() => {
        this.areaData.forEach((elem, index) => {
          this.$refs.tree[index].setCheckedKeys(
            this.tableData[area_index].ship_regions_ids.split(",")
          );
        });
      }, 500);
    },
    /**批量设置配送区域 */
    setAddress () {
      this.areaAddress = '';//批量修改配送区域清空配送区域下标
      this.selectDeliveryArea.display();
    },
    // 渲染区域配送组件
    renderContent (h, { node, data, store }) {
      let childrenChecked = 0;
      let childrenSum = 0;
      if (node.level === 1) {
        if (this.special1.indexOf(node.data.local_name) !== -1) {
          childrenSum++;
          if (node.checked) {
            childrenChecked++;
          }
        }
        node.childNodes.forEach(elem => {
          if (this.special2.indexOf(elem.data.local_name) !== -1) {
            childrenSum++;
            if (elem.checked) {
              childrenChecked++;
            }
          }
          elem.childNodes.forEach(elem1 => {
            childrenSum++;
            if (elem1.checked) {
              childrenChecked++;
            }
          });
        });
      }
      if (node.level === 2) {
        if (this.special2.indexOf(node.data.local_name) !== -1) {
          childrenSum++;
          if (node.checked) {
            childrenChecked++;
          }
        }
        node.childNodes.forEach(elem => {
          childrenSum++;
          if (elem.checked) {
            childrenChecked++;
          }
        });
      }
      data.childrenSum = childrenSum;
      data.childrenChecked = childrenChecked;
      return (
        <span>
          <span>{data.local_name}</span>
          <span style="color:#5688d9">
            {childrenChecked === 0 ? "" : `（${childrenChecked}）`}
          </span>
        </span>
      );
    },
    // 选中状态变化
    handleCheckChange (data, checked, indeterminate) {
      // return
      this.checkedNodesList = [];
      this.areaData.forEach((elem, index) => {
        this.checkedNodesList = this.checkedNodesList.concat(
          this.$refs.tree[index].getCheckedNodes()
        );
      });
      this.checkedNodesSum = 0;
      this.checkedNodesNum = 0;
      this.checkedNodesList.forEach(elem => {
        if (elem.level === 1) {
          this.checkedNodesSum++;
          if (this.special1.indexOf(elem.local_name) !== -1) {
            this.checkedNodesNum++;
          }
        }
        if (elem.level === 2 && this.special2.indexOf(elem.local_name) !== -1) {
          this.checkedNodesNum++;
        }
        if (elem.level === 3) {
          this.checkedNodesNum++;
        }
      });
      this.checkedAll =
        this.checkedNodesSum === this.totalData.length ? true : false;
    },
    // 配送区域全选
    setCheckedNodes (e) {
      // return
      this.areaData.forEach((elem, index) => {
        if (e) {
          this.$refs.tree[index].setCheckedNodes(this.areaData[index]);
        } else {
          this.$refs.tree[index].setCheckedNodes([]);
        }
      });
    },
    //保存配送区域
    submitDeliveryArea () {
      let str = "";
      let arr = [];
      let ids = "";
      this.checkedNodesList.forEach(item => {
        //配送区域ID，一级、二级配送区域，加是否全选标识 比如，二级配送区域ID是 1120，如果全选就记成1120_1
        if (item.level === 1 || item.level === 2) {
          ids += `${item.id}_1,`;
        } else {
          ids += `${item.id},`;
        }
        if (item.level === 1) {
          arr.push({ ...item, children: [] });
        }
        if (item.level === 2) {
          let flog = false;
          arr.forEach(elem => {
            if (elem.id === item.parent_id) {
              flog = true;
              elem.children.push({ ...item, children: [] });
            }
          });
          if (flog === false) {
            this.totalData.forEach(elem1 => {
              if (elem1.id === item.parent_id) {
                arr.push({ ...elem1, children: [{ ...item, children: [] }] });
              }
            });
          }
        }
        if (item.level === 3) {
          let flog = false;
          let index = 0;
          let index1 = 0;
          arr.forEach((elem, i) => {
            elem.children.forEach((elem2, i1) => {
              if (elem2.id === item.parent_id) {
                index = i;
                index1 = i1;
                flog = true;
                elem2.children.push({ ...item, children: [] });
              }
            });
          });
          if (flog === false) {
            this.totalData.forEach(elem1 => {
              elem1.children.forEach(elem3 => {
                if (elem3.id === item.parent_id) {
                  arr.push({
                    ...elem1,
                    children: [
                      { ...elem3, children: [{ ...item, children: [] }] }
                    ]
                  });
                }
              });
            });
          }
        }
      });
      arr.forEach((elem1, index1) => {
        if (elem1.childrenChecked === elem1.childrenSum) {
          return (str += `<span style="color:red;">${elem1.local_name}</span>;`);
        } else {
          str += `<span style="color:red;">${elem1.local_name}</span> `;
          elem1.children.forEach((elem2, index2) => {
            if (elem2.childrenChecked === elem2.childrenSum) {
              return (str += `${elem2.local_name},`);
            } else {
              str += `${elem2.local_name}（`;
              elem2.children.forEach((elem3, index3) => {
                if (elem2.children.length - 1 === index3) {
                  str += `${elem3.local_name}）`;
                } else {
                  str += `${elem3.local_name},`;
                }
              });
            }
          });
        }
      });
      if (this.areaAddress !== '') {
        const index = this.tableData.findIndex(item => item.spec_value_id === this.areaAddress.spec_value_id);
        this.tableData[index].ship_regions_ids = ids;
        this.tableData[index].ship_regions = str;
      } else {//批量设置
        this.tableData.forEach(key => {
          key['ship_regions_ids'] = ids
          key['ship_regions'] = str
        })
      }
      /** 异步更新skuInfo数据 */
      this.$emit('skuTable', this.tableData)
      this.selectDeliveryArea.dismiss();
    },
    /** 批量设置价格*/
    setBatch (val) {
      this.batch = ''
      this.isShowBatch = !this.isShowBatch
      this.activeVal = val
    },
    changeBatch (val) {//批量设置验证数据格式
      if ((this.activeVal == 5 && !/^[0-9]\d*$/.test(val)) || parseInt(val) < 0 || parseInt(val) > 99999999) { // 库存
        val = ''
        this.batch = ''
      } else if ((RegExp.money.test(val) == false) || Math.abs(val) != val || parseInt(val) < 0 || parseInt(val) > 99999999) { // 成本价 价格
        val = ''
        this.batch = ''
      }
    },
    /** 保存批量设置值 */
    saveBatch () {
      let _desc;
      let _keys;//批量设置表格字段key值
      switch (this.activeVal) {
        case 1:
          _desc = '成本价'
          _keys = 'cost'
          break
        case 2:
          _desc = '市场价'
          _keys = 'mktprice'
          break
        case 3:
          _desc = '会员价'
          _keys = 'price'
          break
        case 4:
          _desc = '集采价'
          _keys = 'original_price'
          break
        case 5:
          _desc = '库存'
          _keys = 'quantity'
          break
        default:
          _desc = '库存'
      }
      const checkResult = [1, 2, 3, 4].includes(this.activeVal) ? RegExp.money.test(this.batch) : parseInt(this.batch) >= 0 && parseInt(this.batch) < 99999999 && /^[0-9]\d*$/.test(this.batch)
      if (!checkResult && this.batch <= 0) {
        [1, 2, 3, 4].includes(this.activeVal) ? this.$message.error(`请输入一个有效的${_desc}数据`) : this.$message.error(`请输入一个有效的${_desc}数据,${_desc}为整数`)
        this.batch = ''
        return
      }
      /** 批量设置 */
      if ([1, 2, 3, 4, 5].includes(this.activeVal)) {

        this.tableData.forEach(key => { key[_keys] = this.batch })
      }
      this.validateError = this.validateError.filter(key => key[2] !== _keys)
      this.isShowBatch = !this.isShowBatch
      /** 异步更新skuInfo数据 */
      this.$emit('skuTable', this.tableData)
      console.log(this.validateError);
    },

    /** 取消批量设置值 */
    cancelBatch () {
      this.isShowBatch = !this.isShowBatch
    },

    /** 数据改变之后 抛出数据 */
    updateSkuTable (index, scope, item) {
      console.log('数据改变之后 抛出数据', item);
      /** 进行自定义校验 判断是否是数字（小数也能通过）重量 */
      if ((!/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/.test(scope.row[item]) && item === 'weight') || parseInt(scope.row[item]) < 0 || parseInt(scope.row[item]) > 99999999) { // 校验未通过 加入错误存储列表中
        this.validateError.push([index, scope.$index, item])
        this.validatatxt = '请输入0~99999999之间的数字值'
      } else if ((item === 'quantity' && !/^[0-9]\d*$/.test(scope.row[item])) || Math.abs(scope.row[item]) != scope.row[item] || parseInt(scope.row[item]) < 0 || parseInt(scope.row[item]) > 99999999) { // 库存
        scope.row[item] = ''
        this.validateError.push([index, scope.$index, item])
        // this.validatatxt = '请输入0~99999999之间的整数'
      } else if (((item === 'cost' || item === 'price' || item === 'mktprice' || item === 'original_price') && !RegExp.money.test(scope.row[item])) || Math.abs(scope.row[item]) != scope.row[item] || parseInt(scope.row[item]) < 0 || parseInt(scope.row[item]) > 99999999) { // 成本价 价格
        scope.row[item] = ''
        this.validateError.push([index, scope.$index, item])
        this.validatatxt = '请输入0.01~9999999.99之间的正数'
      } else {
        this.validateError.forEach((key, _index) => {
          if (key[0] === index && key[1] === scope.$index) {
            this.validateError.splice(_index, 1)
          }
        })
      }
      /** 异步更新skuInfo数据 */
      this.$emit('skuTable', this.tableData)
    }
  }
}
</script>

<style lang="scss" type="scss" scoped>
/** 批量设置 */
.batch-all {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

/*带校验模块*/
.input-error-model {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  color: red;
  line-height: 16px;
  // margin-bottom: 20px;

  span {
    color: #f56c6c;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
    position: absolute;
    top: 66%;
    left: 6%;
  }
}

/deep/ .validat_error {
  border-color: red;
}

p.stock-tip {
  color: #f00;
  font-size: 13px;
}

.el-tree-box {
  margin-bottom: 10px;
  max-height: 500px;
  display: flex;
  justify-content: space-between;
  overflow-y: auto;
}

.el-tree-bottom {
  display: flex;
  justify-content: space-between;
}

.displayBoard {
  width: 273px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  text-align: left;
}

/deep/ .el-input-number__decrease,
/deep/ .el-input-number__increase {
  display: none;
}

/deep/ .el-table td.el-table__cell {
  padding: 39px 0
}
</style>
