/**
 * Created by Andste on 2018/5/28.
 */
import request from '@/utils/request'
import {domain} from '@/../ui-domain'
import {Foundation} from '@/../ui-utils'
import {$xDialog} from '@/components/x-dialog/dialog.proxy';
import {$goodsCategory} from "@/pages/goods/services/goods-category";
import * as API_Login from '@/api/login';
import {getSellerShopExtAuth} from '@/api/account'
var provinceWatcher, cityWatcher, countyWatcher

export default {
  props: {
    ShowCake: {//是否显示蛋糕页签
      type: Boolean,
      default: true
    },
    pickupGift: {//判断是否是礼包编辑新增页面  自提开关选择
      type: Number,
      default: 0
    },
    // 显示dialog
    show: {
      type: Boolean,
      default: false
    },
    // 显示每一行的挑选按钮
    showChooseBtn: {
      type: Boolean,
      default: false
    },
    // 默认数据
    defaultData: {
      type: Array,
      default: () => ([])
    },
    // 排除
    excludeData: {
      type: Array,
      default: () => ([])
    },
    // 商品列表
    goodsApi: {
      type: String,
      default: `seller/shopGoods`
    },
    // 京东商品分类
    jdCategoryApi: {
      type: String,
      default: `jd/category/depth/@depth`
    },
    // 是否套餐商品
    showCombo: {
      type: Number,
      default: 0
    },
    // 自有商品分类
    sellerCategoryApi: {
      type: String,
      default: `seller/goods/category/depth/@depth`
    },

    // 获取集市导航APi   京东自有==
    navigationsApi: {
      type: String,
      default: `seller/pages/market/site-navigations`
      // params: { client_type :'PC' }
    },
    // 是否补单
    isSupplement: {
      type: String,
      default: '0'
    },
    // 补单所在的店铺
    supplementShopId: {
      type: String | Number,
      default: ''
    },
    isGroup: {
      type: Boolean  ,
      default: false
    }
  },
  data() {
    return {
      goodsSelectorDialog: $xDialog.create({
        title: '商品选择器',
        disableCancel: true,
        disableConfirm: true,
        width: '95vw',
        wrapperClass: 'goods-selector-dialog',
        afterDismiss: () => this.$emit('close', true),
      }),
      maxNumber: Math.pow(10, 12),
      search_type:'goods_name',
      params: {
        // 会员价格大值
        high_price: '',
        // 会员价格小值
        low_price: '',
        // 市场价格大值
        high_mktprice: '',
        // 市场价格小值
        low_mktprice: '',
        // 商品来源，0普通商品，1京东商品，2苏宁商品
        goods_source: 1,
        self_goods: '2',
        // 利润率大值
        profit_max: '',
        // 利润率小值
        profit_min: '',
        // 最低利润率
        shop_profit_min: '',
        // 最高利润率
        shop_profit_max: '',
        // 关键词
        keywords: '',
        // 商品名称
        keyword: '',
        // 商品编号
        goods_sn:'',
        // 页码
        page_no: 1,
        // 分页数
        page_size: 20,
        // 排序
        sort: '',
        category_path: '',
      },
      typeList:[
        {id:0, type:'全部'},
        {id:1, type:'有货'},
        {id:2, type:'无货'},
      ],
      JDCategory: $goodsCategory.create(),
      categories: [],
      goodsSortList: [
        {
          label: '按时间正序',
          value: 'time_asc'
        },
        {
          label: '按时间倒序',
          value: 'time_desc'
        },
        {
          label: '按利润率从小到大',
          value: 'profit_asc'
        },
        {
          label: '按利润率从大到小',
          value: 'profit_desc'
        },
        {
          label: '按会员价从小到大',
          value: 'price_asc'
        },
        {
          label: '按会员价从大到小',
          value: 'price_desc'
        }
      ],
      chooseChangeList: [],
      chooseAllList: [],
      tableData: {},
      tableHeight: 0,
      /** 加载状态 */
      loading: false,
      /** 商品列表 */
      goodsList: [],
      /** 已选列表 */
      selectedGoods: {},
      dialogVisible: this.show,
      // 前台域名
      buyerDomain: domain.buyer_pc,
      canChangeVal: true,
      // 获取集市导航
      fdList: [],
      areaNum:1,
      provinceList: [],
      cityList: [],
      countyList: [],
      townList: [],
      areaSelectData:{
        province:'',
        city: '',
        county: '',
        town: '',
        jd_area_state: 0
      },
      quantityStatus: false
    }
  },
  watch: {
    show(newVal) {
      this.dialogVisible = newVal;
      if (newVal) {
        this.goodsSelectorDialog.display();
        this.$nextTick(()=>{
          // this.$refs.tables.toggleSearch(true) // 展示高级搜索
          let params_his
          try{
            const fr = localStorage.getItem('sshl__shop_fr') || 'nopre'
            const k = `${fr}@${this.$route.path}`
            params_his = this.$ls.get(k) // 历史搜索数据
            console.log(params_his, '--params_his--')
            if(params_his){
              this.params = {
                ...this.params,
                ...params_his.params
              }
              this.search_type = params_his.search_type || 'goods_name'
              this.areaSelectData = params_his.areaSelectData || {
                province:'',
                city: '',
                county: '',
                town: '',
                jd_area_state: 0
              }
              this.areaNum = params_his.areaNum || 1
            }
          }catch(e){
            console.error(e, '--params_his error--')
          }
          if(!this.provinceList.length){
            this.getAreaHandle('province')
          }
          if(this.areaSelectData.province && !this.cityList.length){
            this.getAreaHandle('city', {id:this.areaSelectData.province})
          }
          if(this.areaSelectData.city && !this.countyList.length){
            this.getAreaHandle('county', {id:this.areaSelectData.city})
          }
          if(this.areaSelectData.county && !this.townList.length){
            this.getAreaHandle('town', {id:this.areaSelectData.county})
          }
          if (this.pickupGift == 1) {
            this.params.self_goods='2'
          }
          if (this.goodsList.length === 0 || params_his) {
            this.categories = params_his ? params_his.categories : [];
            this.GET_Goods();
            this.getNavs();
          }
          if (this.defaultData && this.defaultData.length >= 0) {
            this.chooseChangeList = JSON.parse(JSON.stringify(this.defaultData));
          } else {
            this.chooseChangeList = [];
          }
          // 省 watcher
          provinceWatcher = this.$watch('areaSelectData.province', function(newValue) {
            console.log(newValue, 'province')
            this.areaSelectData.city=''
            this.areaSelectData.county=''
            this.areaSelectData.town=''
            if (newValue === ''){
              this.areaNum = 1
              this.getAreaHandle('province')
            }else {
              this.areaNum = 2
              this.getAreaHandle('city', {id:newValue})
            }
          })
          // 市 watcher
          cityWatcher = this.$watch('areaSelectData.city', function(newValue){
            console.log(newValue, 'city')
            this.areaSelectData.county=''
            this.areaSelectData.town=''
            if (this.areaSelectData.province === '') return
            if (newValue === '') this.areaNum=2;
            else {
              this.areaNum=3
              this.getAreaHandle('county', {id:newValue})
            }
          })
          // 区 watcher
          countyWatcher = this.$watch('areaSelectData.county', function(newValue){
            console.log(newValue, 'county')
            this.areaSelectData.town=''
            if (this.areaSelectData.city === '') return
            if (newValue === '') this.areaNum=3;
            else {
              this.areaNum=4
              this.getAreaHandle('town', {id:newValue})
            }
          })
        })
      } else {
        this.goodsSelectorDialog.dismiss();
        // unwatcher
        provinceWatcher()
        cityWatcher()
        countyWatcher()
      }
    },
    dialogVisible(newVal) {
      newVal === false && this.$emit('close')
    },
    // 'defaultData': 'defaultDataChanged'
  },
  filters: {
    formatPrice(price) {
      return '￥' + String(Number(price).toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  mounted() {
    if(sessionStorage.getItem('MixinIsFormEnterprise')){
      this.MixinIsFormEnterprise = JSON.parse(sessionStorage.getItem('MixinIsFormEnterprise'))
    }
    API_Login.getLoginType().then(res => {
      if (res && res.parentLogin === 1) {
        this.MixinIsFormEnterprise = true;
        sessionStorage.setItem('MixinIsFormEnterprise', true)
      } else sessionStorage.setItem('MixinIsFormEnterprise' ,false)
    })
    this.JDCategory.tryReload();
  },
  methods: {
    calcTableHeight() {
      return new Promise(resolve => {
        setTimeout(() => {
          // const height = (document.body.clientHeight - (10 + 30 + 54 + 72 + 55 + 20) - this.$refs.header.clientHeight);
          this.tableHeight = document.body.clientHeight - (54 + 72 + 55 + 20);
          resolve();
        });
      });
    },
    min(obj = {}, minKey = '', maxKey = '', disable0 = false) {
      const min = obj[minKey] || 0;
      const max = obj[maxKey] || this.maxNumber;
      if (parseFloat(min) > parseFloat(max)) {
        obj[minKey] = max;
      } else if (disable0 && min < 0) {
        obj[minKey] = 0;
      }
    },
    max(obj = {}, minKey = '', maxKey = '') {
      const min = obj[minKey] || 0;
      const max = obj[maxKey] || this.maxNumber;
      if (parseFloat(max) < parseFloat(min)) {
        obj[maxKey] = min;
      }
    },
    getNavs() {
      let url = this.navigationsApi
      request({
        url,
        method: 'get',
        loading: false,
        params: {client_type: 'PC'}
      }).then(response => {
        response.forEach(res => {
          res.self_goods = Foundation.getAllKey(res.url).self_goods
        })
        //过滤商城套餐页签（只在智能套餐卡、小程序、双选集市显示）
        this.fdList = response.filter(item => {
          return item.navigation_id !== 77
        });
        if (this.pickupGift == 2) {
          this.fdList = []
          this.params.self_goods='7'
        } else if (this.pickupGift == 3) {
          this.fdList = []
          this.params.self_goods = '8'
        } else if (this.pickupGift == 1) {
          if(this.isGroup)  this.fdList=this.fdList.filter(item=>item.navigation_id !== 76)
          this.params.self_goods='2'
        }
        
        if (this.ShowCake==false) {
          this.fdList=this.fdList.filter(item=>item.navigation_id!=76)
        }
        // this.getSellerShopExtAuths()
      })
    },
    async getSellerShopExtAuths () {
      if (this.pickupGift == 1) return
      const res = await getSellerShopExtAuth(this.$store.getters.shopInfo.shop_id)
      if (this.pickupGift==2) {
        if (res.shop_self_goods_open == 'OPEN') { 
          this.fdList.push({
            "navigation_id": 7,
            "navigation_name": "商城自提商品",
            "url": "/markets/privateGoods?self_goods=7",
            "client_type": "PC",
            "image": "",
            "sort": 4,
            "shop_id": null,
            "display": null,
            "self_goods": "7"
          })    
        }
      } else if (this.pickupGift == 3) {
        if (res.shop_self_mailed_goods_open == 'OPEN') {       
          this.fdList.push({
            navigation_id: 8,
            navigation_name: "商城邮寄商品",
            "url": "/markets/privateGoods?self_goods=8",
            "client_type": "PC",
            "image": "",
            "sort": 4,
            "shop_id": null,
            "display": null,
            "self_goods": "8"
          })
        } 
      }
    },
    resetParams() {
      this.params = {
        goods_source: this.params.goods_source,
        self_goods: this.params.self_goods,
        page_no: 1,
        page_size: 20,
      }
    },
    tabClick () {
      if (this.params.self_goods === '9') {
        this.JDCategory = $goodsCategory.create('9');
      } else {
        this.JDCategory = $goodsCategory.create();
      }
      this.resetParams();
      this.search_type = 'goods_name';
      if (this.params.self_goods === '2') {
        this.params.goods_source = 1
        this.areaSelectData.jd_area_state = 0
        this.areaSelectData.province = ''
      } else if (this.params.self_goods === '4') {
        this.params.goods_source = 5
      } else {
        if(this.params.self_goods === '9')this.params.goods_source = 3
        else this.params.goods_source = 0
      }

      this.GET_Goods();
    },
    handleSearch(searchOpt){
      this.GET_Goods(searchOpt);
    },
    // 获取商品选择器数据
    GET_Goods(searchOpt={}) {
      this.loading = true;
      this.showCombo && (this.params.is_combo = 0)
      this.params.category_path = Array.isArray(this.categories) ? this.categories.join('|') : this.categories;
      this.params.market_enable = 1
      this.params.disabled = 1
      this.params.is_auth = 1
      this.params.shop_goods_status = 1;
      if (this.supplementShopId) {
        this.params['shop_id'] = this.supplementShopId
      }
      // console.log(this.params,'---params---')
      if(searchOpt && searchOpt.initPageNo) this.params.page_no = 1;
      if(searchOpt && searchOpt.saveParams){
        try{
          const fr = localStorage.getItem('sshl__shop_fr') || 'nopre'
          const k = `${fr}@${this.$route.path}`
          this.$ls.set(k, {
            params: this.params,  // 大部分搜索参数在这
            search_type: this.search_type,  // 关键词的下拉
            categories: this.categories, // 分类的选中数据
            areaSelectData: this.areaSelectData, // 地区选择数据
            areaNum: this.areaNum,
          })
        }catch(e){}
      }

      if (this.params.goods_source===1 && this.areaSelectData.province!==''){
        let jd_area = '' + this.areaSelectData.province + '_'
        if (this.areaSelectData.city!=='') jd_area += this.areaSelectData.city + '_'
        else jd_area += '0_'
        if (this.areaSelectData.county!=='') jd_area += this.areaSelectData.county + '_'
        else jd_area += '0_'
        if (this.areaSelectData.town!=='') jd_area += this.areaSelectData.town
        else jd_area += '0'
        this.params.jd_area = jd_area
        this.params.jd_area_state = this.areaSelectData.jd_area_state
        this.quantityStatus = true
      } else if(this.params.jd_area) {
        delete this.params.jd_area
        delete this.params.jd_area_state
        this.quantityStatus = false
      }else this.quantityStatus = false
      delete this.params.goods_name
      delete this.params.order_sn
      delete this.params.goods_sn
      delete this.params.supplier_name
      this.params[this.search_type] = this.params.keywords
      let url = this.goodsApi;

      const keys = Object.keys(this.params);

      for (const key of keys) {
        const isMin = key.includes('_min');
        const isMax = key.includes('_max');
        const isLow = key.includes('low_');
        const isHigh = key.includes('high_');

        const value = this.params[key];

        if (isMin || isMax || isLow || isHigh) {
          if (value === 0 || value === '') {
            delete this.params[key];
          }
        }
      }
      if (this.pickupGift==2) {//如果是礼包编辑新增 自提礼包 要过滤非餐类商品
        this.params.catering = 2
        this.params.self_goods = '7'
        delete this.params.goods_source
      } else if (this.pickupGift==3) { 
        this.params.self_goods = '8'
        delete this.params.goods_source
      }
      let params = {
        ...this.params
      }
      console.log(this.params, '---params---');
      if (this.params.self_goods == 8) {//邮寄商品
        params.self_goods = 7;
        params.delivery_method = 1;
      } else if (this.params.self_goods == 7) {//自提商品
        params.self_goods = 7;
        params.delivery_method = 2;
      }
      console.log(this.params.self_goods);
      request({
        url,
        method: 'get',
        loading: true,
        params: params
      }).then(response => {
        this.tableData = response;

        this.$nextTick(() => {
          this.canChangeVal = false;

          this.tableData.data.forEach(e => {
            let haveSameId = false;// 设置标志，回显已选数据期间不触发chooseChangeList赋值
            for (let index = 0; index < this.chooseChangeList.length; index++) {
              haveSameId = false;

              const ee = this.chooseChangeList[index];
              if (e.goods_id === ee.goods_id) {
                haveSameId = true;
                // 将新值赋值给旧值
                this.chooseChangeList[index] = e;
                break;
              }
            }

            this.$forceUpdate();

            if (haveSameId) {
              this.$refs.tables.toggleRowSelection(e, true);
            } else {
              this.$refs.tables.toggleRowSelection(e, false);
            }
          });
          this.canChangeVal = true;

          this.loading = false;
        })
        this.$refs.tables.$refs.table.doLayout();
      })
    },
    // 批量挑选抛出方法
    chooseAll() {
      if (this.chooseChangeList.length) {
        this.$emit('chooseAll', this.chooseChangeList);
        this.$emit('close');
      } else {
        this.$message.error('请先选择商品');
      }
    },
    selectionChange(val) {
      if (this.canChangeVal) {
        this.chooseChangeList.push.apply(this.chooseChangeList, val);
        for (var i = 0; i < this.chooseChangeList.length; i++) {
          for (var j = i + 1; j < this.chooseChangeList.length; j++) {
            if (this.chooseChangeList[i]['goods_id'] === this.chooseChangeList[j]['goods_id']) {
              this.chooseChangeList.splice(j, 1);
              j--;
            }
          }
        }
      }
    },
    handleSelect(selection, row) {
      if (!selection.includes(row)) {
        for (let i = 0; i < this.chooseChangeList.length; i++) {
          const e = this.chooseChangeList[i];
          if (e.goods_id === row.goods_id) {
            this.chooseChangeList.splice(i, 1);
          }
        }
      }
    },
    handleSelectAll(selection) {
      if (!selection.length) {
        const rows = this.tableData.data;
        for (let tableIndex = 0; tableIndex < rows.length; tableIndex++) {
          const row = rows[tableIndex];
          for (let i = 0; i < this.chooseChangeList.length; i++) {
            const e = this.chooseChangeList[i];
            if (e.goods_id === row.goods_id) {
              this.chooseChangeList.splice(i, 1);
            }
          }
        }
      }
    },
    handleSortChange({order, prop}) {
      if (order) {
        this.params.sort = `${prop}_${order.includes('asc') ? 'asc' : 'desc'}`;
      } else {
        this.params.sort = '';
      }
      this.GET_Goods();
    },
    // 挑选抛出方法
    choose(row) {
      this.$emit('choose', row)
      this.$emit('close')
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_Goods();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_Goods();
    },
    /** 当默认数据发生改变 */
    defaultDataChanged(newVal) {
      if (newVal && newVal.length >= 0) {
        this.chooseChangeList = this.defaultData;
      } else {
        this.chooseChangeList = [];
      }
    },
  }
}
