/**
 * Created by Andste on 2018/5/28.
 */
import {$xDialog} from '@/components/x-dialog/dialog.proxy';
import * as API_goods from "@/api/goods";

export default {
  props: {
    // 显示dialog
    showm: {
      type: Boolean,
      default: false
    },
    btnShow: {
      type: Boolean,
      default: true
    },
    // 显示上下架
    shangxiajia: {
      type: Boolean,
      default: true
    },
    shop_type: {
      type: Number,
      default: 0
    },
    mall_type: {
      type: Number,
      default: 0
    },
    idsList: {
      type: Array,
      default: () => {
        return []
      }
    },
    allId: {
      type: Number,
      deafult: 0
    },
    is_qiye_flag: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialogRef: $xDialog.create({
        title: '改价',
        width: '868px',
        displayFooterExtras: true,
        disableCancel: true,
        disableConfirm: true,
        wrapperClass: 'goods-reset-price',
        afterDismiss: () => {
          this.$emit('close', true);
        }
      }),
      form: {
        change_type: 0,//改价方式
        enterprise_sum_price: '',//分销总价
        shop_sum_price: '',//销售总价
        shop_combo_status:1,//是否启用  1 启用  0 不启用
      },
      formRules: {
        enterprise_sum_price: [
          { required: true, message: '请输入分销总价', trigger: 'blur' },
          {
            validator: async (rule, value, callback) => {
              const reg = /^(0|[1-9]\d*)(\.\d{1,2})?$/
              if (isNaN(value) || value <= 0 || value > 99999999 || !reg.test(value)) {
                callback(new Error('请输入小数点后两位的正数'))
              }else {
                callback()
              }
            }
          }
        ],
        shop_sum_price: [
          { required: true, message: '请输入销售总价', trigger: 'blur' },
          {
            validator: async (rule, value, callback) => {
              const reg = /^(0|[1-9]\d*)(\.\d{1,2})?$/
              if (isNaN(value) || value <= 0 || value > 99999999 || !reg.test(value)) {
                callback(new Error('请输入小数点后两位的正数'))
              }else {
                callback()
              }
            }
          }
        ],  
      },
      radio: '2',
      radio1: '2',
      options: [{
        label: '会员总价',
        value: 1
      }, {
        label: '市场总价',
        value: 2
      }],
      optionss: [{
        label: '会员总价',
        value: 1
      }, {
        label: '市场总价',
        value: 2
      }],
      options1: [{
        label: '上调',
        value: 'UP'
      }, {
        label: '下降',
        value: 'DOWN'
      }],
      dialogVisible: this.showm,
      enterprise_price: {
        price_type: 1,
        ratio: 0,
        symbol: 'UP'
      },
      shop_price: {
        price_type: 2,
        ratio: 0,
        symbol: 'UP'
      },
      shop_goods_status: '1',
      cop: '1'
    }
  },
  watch: {
    showm (newVal) {
      if (newVal) {
        this.dialogRef.display();
        //查询上次改价记录数据
        const getGoodsId = this.batch ? 0 : this.idsList[0]
        //如果是企业端集市触发了选中项就返回
        this.resetmed()
        if (getGoodsId instanceof Object || getGoodsId===null ||getGoodsId===undefined) {
          return
        }  
        // API_goods.getChangePriceLog(getGoodsId).then(res => {
        //   if (res) {
        //     //分销价
        //     if (res.enterprise_price.symbol=='EQUAL') {
        //       this.radio = '2'//等于
        //     } else {
        //       this.radio='1'
        //       this.enterprise_price.symbol = res.enterprise_price.symbol
        //     }
        //     this.enterprise_price.ratio=res.enterprise_price.ratio?res.enterprise_price.ratio:0
        //     this.enterprise_price.price_type=res.enterprise_price.price_type
        //     //销售价
        //     if (res.shop_price.symbol=='EQUAL') {
        //       this.radio1 = '2'//等于
        //     } else {
        //       this.radio1='1'
        //       this.shop_price.symbol = res.shop_price.symbol
        //     }
        //     this.shop_price.ratio=res.shop_price.ratio?res.shop_price.ratio:0
        //     this.shop_price.price_type=res.shop_price.price_type
        //   } else {
        //     this.resetmed()
        //   }
        // })
      } else {
        this.dialogRef.dismiss();
      }
    },
  },
  methods: {
    /**初始化重置表单 */
    resetmed () {
      this.form= {
        change_type: 0,//改价方式
        enterprise_sum_price: '',//分销总价
        shop_sum_price: '',//销售总价
        shop_combo_status:1,//是否启用  1 启用  0 不启用
      }
      this.radio = '2'
      this.radio1 = '2'
      this.enterprise_price= {
        price_type: 1,
        ratio: 0,
        symbol: 'UP'
      }
      this.shop_price= {
        price_type: 2,
        ratio: 0,
        symbol: 'UP'
      }
    },
    handleInputChange () {
      const reg=/^(0|[1-9]\d*)(\.\d{1,2})?$/
      if(!reg.test(this.enterprise_price.ratio)){
        this.enterprise_price.ratio=0
      }
      if (this.enterprise_price.ratio < 0) {
        this.enterprise_price.ratio = 0
      } else if (this.enterprise_price.symbol === 'DOWN' && this.enterprise_price.ratio >= 100) {
        this.enterprise_price.ratio = 100
      }
    },
    handleInputChange1 () {
      const reg=/^(0|[1-9]\d*)(\.\d{1,2})?$/
      if(!reg.test(this.shop_price.ratio)){
        this.shop_price.ratio=0
      }
      if (this.shop_price.ratio < 0) {
        this.shop_price.ratio = 0
      } else if (this.shop_price.symbol === 'DOWN' && this.shop_price.ratio >= 100) {
        this.shop_price.ratio = 100
      }
    },
    close() {
      console.log('关闭');
      this.$emit('close')
    },
    resSelect() {
      this.$emit('res')
    },
    /** 确认 */
    handleConfirm () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.enterprise_price.symbol === 'DOWN' && this.enterprise_price.ratio >= 100) {
            this.enterprise_price.ratio = 100
          }
          if (this.shop_price.symbol === 'DOWN' && this.shop_price.ratio >= 100) {
            this.shop_price.ratio = 100
          }
          let temp;
          if (this.form.change_type === 0) {//改价方式 手动录入改价
            temp = {
              change_type: this.form.change_type,
              enterprise_sum_price: this.form.enterprise_sum_price,
              shop_sum_price: this.form.shop_sum_price,
              shop_combo_status: this.form.shop_combo_status,
            };
          } else { 
            temp = {
              change_type: this.form.change_type,
              shop_combo_status: this.form.shop_combo_status,
              enterprise_price: JSON.parse(JSON.stringify(this.enterprise_price)),
              shop_price: JSON.parse(JSON.stringify(this.shop_price)),
            };
      
            if (this.radio === '2') {
              temp.enterprise_price.symbol = 'EQUAL'
            }
            if (this.radio1 === '2') {
              temp.shop_price.symbol = 'EQUAL'
            }
          }
          this.$emit('confirm1', temp);
    
          this.enterprise_price.symbol = 'UP';
          this.shop_price.symbol = 'UP';
        }
      });
    },
  }
}
