
import { Component, Vue, Watch } from "vue-property-decorator";
import { $layout } from "@/layouts/services/layout.service";
import { turnUrl } from "@/utils";
import * as API_order from "@/api/order";
import * as API_login from "@/api/login";
import Storage from "@/utils/storage";
import { getSellerShopExtAuth } from "@/api/account";
import broadcast, { MESSAGE_TYPES } from '@/utils/broadcast';
import checkVersion from "@/utils/check-version";

@Component({
  name: "app",
})
export default class App extends Vue {
  shutDown = false;
  @Watch("$route", { deep: true, immediate: true })
  async routechange(to, from) {
    if (to.name === "shopGoodsList") {
      API_login.getAutoUpDownStatus().then((resStatus) => {
        this.$store.getters.permission_routers.forEach((item) => {
          if (item.path === "/shop-goods") {
            // 开启智能运营
            if (resStatus.data) {
              let status = false;
              item.children.forEach((value) => {
                if (value.path === "intel-operation") {
                  status = true;
                }
              });
              // 智能运营不存在时添加
              if (!status && this.shutDown) {
                item.children.push({
                  path: "intel-operation",
                  component: () =>
                    import("@/views/shopGoods/intelOperation.vue"),
                  name: "intelOperation",
                  meta: {
                    title: "intelOperation",
                  },
                });
              } else if (
                !status &&
                !this.shutDown &&
                sessionStorage.getItem("MixinIsFormEnterprise") !== "false"
              ) {
                this.$router.go(0);
              }
            } else {
              // 关闭智能运营
              item.children.forEach((value, index) => {
                if (value.path === "intel-operation") {
                  this.shutDown = true;
                  item.children.splice(index, 1);
                }
              });
            }
          }
        });
      });
    } else if (to.name === "userList") {
      const res = await getSellerShopExtAuth(this.$store.getters.shopInfo.shop_id)
      const user_recharge = res.user_recharge === 'OPEN'// 用户充值权限
      this.$store.getters.permission_routers.forEach((item) => {
        if (item.path === "/users") {
          // 开启用户充值
          if (user_recharge) {
            let status = false;
            item.children.forEach((value) => {
              if (value.path === "userschong") {
                status = true;
              }
            });
            // 用户充值不存在时添加
            if (!status && this.shutDown) {
              item.children.push({
                path: "userschong",
                component: () =>
                  import("@/views/users/userschong.vue"),
                name: "userschong",
                meta: { title: "userschong" },
              });
            } else if (
              !status &&
              !this.shutDown &&
              sessionStorage.getItem("MixinIsFormEnterprise") !== "false"
            ) {
              this.$router.go(0);
            }
          } else {
            // 关闭用户充值
            item.children.forEach((value, index) => {
              if (value.path === "userschong") {
                this.shutDown = true;
                item.children.splice(index, 1);
              }
            });
          }
        }
      });
    }
    const isCardSale = await API_order.getShopExtAuth().then((resStatus) => {
      if (resStatus && resStatus.shop_disabled === 1) {
        const user = Storage.getItem("seller_user");
        const fromEnterSellerUser = JSON.parse((sessionStorage as any).getItem("from_enter_seller_user"));
        const member_type = (fromEnterSellerUser || user).member_type;
        this.$store.dispatch("logoutAction").then(() => {
          switch (member_type) {
            case 5:
              window.location.href = "/login?type=combo-card-shop";
              break;
            default:
              window.location.href = "/login";
              break;
          }
        });
      }
      if (resStatus) return resStatus.open === "OPEN";
    });

    this.PermissionAssignment(from, isCardSale, "order", "coupon-order-list");
    this.PermissionAssignment(
      from,
      isCardSale,
      "finance",
      "cancel-after-verification"
    );
    this.PermissionAssignment(from, isCardSale, "finance", "openCardWrite");
    this.PermissionAssignment(from, isCardSale, "finance", "closeCardWrite");
    this.PermissionAssignment(
      from,
      isCardSale,
      "tools",
      "saleCardOpenManagement"
    );
    this.PermissionAssignment(
      from,
      isCardSale,
      "tools",
      "saleCardCloseManagement"
    );
    this.PermissionAssignment(from, isCardSale, "tools", "cardSearch");
  }

  PermissionAssignment(from, flag, parentRoute, childrenRoute) {
    const routerArr = ["order", "finance", "tools"];
    // 大平台开关控制菜单路由入口
    if (from && routerArr.indexOf(from.path.split("/")[1]) !== -1) {
      // 总路由循环
      this.$store.getters.permission_routers.forEach((item) => {
        if (item.path === "/" + parentRoute) {
          if (!flag) {
            // 关闭菜单
            item.children.forEach((value, index) => {
              if (value.path === childrenRoute) {
                item.children.splice(index, 1);
              }
            });
          }
        }
      });
    }
  }

  mounted() {
    const userInfo = Storage.getItem('seller_user');
    (window as any).userInfo = userInfo;
    const shopInfo2021 = Storage.getItem("shopInfo2021");
    if (shopInfo2021) {
      sessionStorage.setItem('shopInfo2021', JSON.stringify(shopInfo2021));
    }
    // 监听账号变化
    broadcast.addListener(MESSAGE_TYPES.ACCOUNT_CHANGED, (newAccount) => {
      if (!(window as any).userInfo || (window as any).userInfo.uid !== newAccount.uid) {
        // this.$store.dispatch("loginToken", { token: newAccount.access_token });
        this.$confirm('检测到其他标签页账号已更改，请刷新页面。', '提示', {
          confirmButtonText: '确定',
          showCancelButton: false,
          showClose: false,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          type: 'warning'
        }).then(() => {
          const shopInfo2021 = Storage.getItem("shopInfo2021");

          if (shopInfo2021) {
            sessionStorage.setItem('shopInfo2021', JSON.stringify(shopInfo2021));
          }
          window.location.reload();
        });
      }
    });

    checkVersion()

    const { tokens, fr } = turnUrl(location.href);
    if (tokens) {
      sessionStorage.setItem("session", tokens);
      this.$store.dispatch("loginToken", { token: tokens });
    }
    if (fr) {
      localStorage.setItem("sshl__shop_fr", fr);
    }
    $layout.mode.subscribe((mode) => {
      this.$el.parentElement!.className = mode;
    });
    const user =
      Storage.getItem("seller_user") ||
      JSON.parse((sessionStorage as any).getItem("from_enter_seller_user"));
    if (user) {
      API_login.getUserRolesPermissions(user.role_id).then((res) => {
        if (!res.length) {
          if (this.$route.path !== "/no-sale-open") {
            this.$router.push({ path: "/no-sale-open" });
          }
        } else {
          if (this.$route.path === "/no-sale-open") {
            this.$router.push({ path: "/" });
          }
        }
      });
    }
  }
}
