<template>
  <x-dialog :proxy="dialogRef">
    <el-form label-width="120px" size="small" :rules="formRules" :model="form" ref="ruleForm">
      <el-form-item label="改价方式">
        <el-radio-group class="mt-2" v-model="form.change_type">
          <el-radio :label="0">手动录入改价</el-radio>
          <el-radio :label="1">按比例改价</el-radio>
        </el-radio-group>
      </el-form-item>
      <template v-if="form.change_type==0">
        <el-form-item label="分销总价">
          <span style="font-size:12px">作为商城对外展示礼包成本价格（分销总价不作为和企业结算时的价格使用）。</span>
          <el-form-item label="分销总价" prop="enterprise_sum_price" label-width="80px">
            <el-input placeholder="请输入分销总价" v-model.trim="form.enterprise_sum_price" clearable
              style="width: 150px" />
          </el-form-item>
        </el-form-item>
        <el-form-item label="销售总价">
          <span style="font-size:12px">作为商城对C端用户的结算价或展示价。</span>
          <el-form-item label="销售总价" prop="shop_sum_price" label-width="80px">
            <el-input placeholder="请输入销售总价" v-model.trim="form.shop_sum_price" clearable
              style="width: 150px" />
          </el-form-item>
        </el-form-item>
        <el-form-item label="是否启用" error="选择不启用，礼包依然将挑选至该站点商城，但是状态为已禁用，需再次启用后，用户才可见。默认直接启用" class="pt-3 reset"
          v-if="shangxiajia">
          <el-radio-group class="mt-2" v-model="form.shop_combo_status">
            <el-radio :label="1">直接启用</el-radio> 
            <el-radio :label="0">暂不启用</el-radio>
          </el-radio-group>
        </el-form-item>
      </template>
      <template v-else>
        <el-form-item class="reset" label="分销总价" error="作为商城对外展示礼包成本价格（分销总价不作为和企业结算时的价格使用）。">
          <div class="row no-gutters align-items-center">
            <div class="col-auto">
              <el-radio v-model="radio" label="1">按照：</el-radio>
            </div>
            <div class="col-auto">
              <el-select v-model="enterprise_price.price_type" clearable placeholder="请选择" style="width: 100px">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
  
              <el-select v-model="enterprise_price.symbol" clearable placeholder="请选择" style="width: 100px; margin: 0 8px">
                <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
  
              <el-input placeholder="请输入内容" @change="handleInputChange" v-model.trim="enterprise_price.ratio" clearable
                style="width: 100px">
              </el-input>
            </div>
            <div class="col-auto ml-2">%</div>
          </div>
          <div class="row no-gutters align-items-center mt-3 mb-2">
            <div class="col-auto">
              <el-radio v-model="radio" label="2">等于：</el-radio>
            </div>
            <div class="col-auto">
              <el-select v-model="enterprise_price.price_type" clearable placeholder="请选择" style="width: 100px">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
        </el-form-item>
  
        <el-form-item label="销售总价" error="作为商城对C端用户的结算价或展示价。"
          class="pt-3 reset">
          <div class="row no-gutters align-items-center">
            <div class="col-auto">
              <el-radio v-model="radio1" label="1">按照：</el-radio>
            </div>
            <div class="col-auto">
              <el-select v-model="shop_price.price_type" clearable placeholder="请选择" style="width: 100px">
                <el-option v-for="item in optionss" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
  
              <el-select v-model="shop_price.symbol" clearable placeholder="请选择" style="width: 100px; margin: 0 8px">
                <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
  
              <el-input placeholder="请输入内容" @change="handleInputChange1" v-model.trim="shop_price.ratio" clearable
                style="width: 100px">
              </el-input>
            </div>
            <div class="col-auto ml-2">%</div>
          </div>
  
          <div class="row no-gutters align-items-center mt-3 mb-2">
            <div class="col-auto">
              <el-radio v-model="radio1" label="2">等于：</el-radio>
            </div>
            <div class="col-auto">
              <el-select v-model="shop_price.price_type" clearable placeholder="请选择" style="width: 100px">
                <el-option v-for="item in optionss" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
        </el-form-item>
  
        <el-form-item label="是否启用" error="选择不启用，礼包依然将挑选至该站点商城，但是状态为已禁用，需再次启用后，用户才可见。默认直接启用" class="pt-3 reset"
          v-if="shangxiajia">
          <el-radio-group class="mt-2" v-model="form.shop_combo_status">
            <el-radio :label="1">直接启用</el-radio> 
            <el-radio :label="0">暂不启用</el-radio>
          </el-radio-group>
        </el-form-item>
      </template>
    </el-form>

    <div slot="footer-extras">
      <el-button @click="resSelect" v-if="btnShow" size="small">重新选择商城</el-button>
      <el-button type="primary" @click="handleConfirm" size="small">确 定</el-button>
    </div>
  </x-dialog>
</template>

<script>
import mixin from './mixin';
import XDialog from '@/components/x-dialog/x-dialog';

export default {
  name: 'EnGaijiaGiftPicker',
  components: { XDialog },
  mixins: [mixin],
  data () {
    return {

    }
  },
  mounted () {
    if (this.$route.name === 'marketGood') {
      this.btnShow = true
    } else if (this.$route.name === 'marketGooda') {
      this.btnShow = false
    }
  }
};
</script>

<style lang="scss">
.goods-reset-price {

  .el-input-group__prepend {
    padding: 0;

    .el-select {
      width: 100px;
      margin: 0;

      * {
        border: none;
      }
    }

    .el-input__inner {
      transform: translateY(0px);
    }
  }

  .el-form-item.is-error .el-input__inner {
    border-color: #DCDFE6 !important;
  }

  .el-input__inner {
    transform: translateY(1px);
  }

  .el-form-item.reset {
    .el-form-item__content {
      padding-top: 28px;
      position: relative;

      .el-form-item__error {
        color: #666;
        position: absolute;
        top: 8px;
      }
    }
  }
}
</style>
