import FloorTagsPicker from './FloorTagsPicker'
import FloorTitlePicker from './FloorTitlePicker'
import CategoryPicker from './CategoryPicker'
import SkuSelector from './SkuSelector'
import TransferTree from './TransferTree/index'
import CountDownBtn from './CountDownBtn/index'
import ElSelectSelect from './ElSelectSelect/index'
import ElDialogXDialog from './ElDialogXDialog/index'
import ElClassification from './ElClassification/index'

import UE from './UE'

const components = {
  FloorTagsPicker,
  FloorTitlePicker,
  ElSelectSelect,
  ElDialogXDialog,
  ElClassification
}

export default {
  ...components,
  install(Vue) {
    Object.keys(components).forEach(key => {
      key !== 'install' && Vue.component(components[key].name, components[key])
    });
  }
}

export {
  CategoryPicker,
  SkuSelector,
  TransferTree,
  CountDownBtn,
  ElSelectSelect,
  UE
}
