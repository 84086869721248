import * as API_Login from '@/api/login'
import * as API_Shop from '@/api/shop'
import Storage from '@/utils/storage'
import jwt_decode from 'jwt-decode'
import {getShopData} from '@/api/shop';
import {$layout} from '@/layouts/services/layout.service';
import Vue from 'vue'
import broadcast, { MESSAGE_TYPES } from '@/utils/broadcast';
// state
const _user = Storage.getItem('seller_user');
const _shop = Storage.getItem('seller_shop');
let isSiteLogin = undefined;
export const state = {
  user: _user,
  shop: _shop,
  isSupplierName: true,
};

function setupUserProfiles(user) {
  if (!user) return;
  $layout.userProfiles.next({
    nickname: user.nickname,
    avatar: user.face,
    actions: [
      {
        icon: 'el-icon-switch-button',
        title: '退出',
        handler: e => $layout.onLogout.next(e),
      },
    ]
  });
}

setupUserProfiles(state.user);

function geLoginType() {
  return new Promise(resolve => {
    if (isSiteLogin !== undefined) {
      resolve(isSiteLogin);
    } else {
      API_Login.getLoginType().then(resp => {
        isSiteLogin = resp.parentLogin === 1;
        resolve(isSiteLogin);
      });
    }
  });
}

// mutations
export const mutations = {
  /**
   * 设置用token登陆的用户信息
   */
  TOKEN_SET_USER: (state, user) => {
    state.user = user;
    // 刷新浏览器，企业跳转到站点应该获取站点的用户信息而非cookie里面的seller_user
    geLoginType().then(resp => {
      if (resp) sessionStorage.setItem('from_enter_seller_user', JSON.stringify(user))
    });
  },
  /**
   * 设置用户信息
   * @param state
   * @param user
   * @constructor
   */
  SET_USER: (state, user) => {
    state.user = user;
    setupUserProfiles(user);
    geLoginType().then(resp => {
      if (resp) {
        sessionStorage.setItem('from_enter_seller_user', JSON.stringify(user));
        sessionStorage.setItem('from_enter_seller_uid', user.uid);
      } else {
        Storage.setItem('seller_user', user);
        Storage.setItem('seller_uid', user.uid);
      }
    });
  },
  /**
   * 设置访问令牌
   * @param state
   * @param token
   * @constructor
   */
  SET_ACCESS_TOKEN: (state, token) => {
    const expires = new Date(jwt_decode(token).exp * 1000)
    Storage.setItem('seller_access_token', token, {expires});
  },
  /**
   * 设置刷新令牌
   * @param state
   * @param token
   * @constructor
   */
  SET_REFRESH_TOKEN: (state, token) => {
    const expires = new Date(jwt_decode(token).exp * 1000)
    Storage.setItem('seller_refresh_token', token);
  },
  /**
   * 移除用户信息
   * @param state
   * @constructor
   */
  REMOVE_USER: (state) => {
    state.user = '';
    Storage.removeItem('seller_user');
    Storage.removeItem('seller_uid');
    $layout.userProfiles.next({});
  },
  /**
   * 移除访问令牌
   * @param state
   * @constructor
   */
  REMOVE_ACCESS_TOKEN: (state) => {
    Storage.removeItem('seller_access_token')
  },
  /**
   * 移除刷新令牌
   * @param state
   * @constructor
   */
  REMOVE_REFRESH_TOKEN: (state) => {
    Storage.removeItem('seller_refresh_token')
  },
  /**
   * 设置店铺信息
   * @param state
   * @param shopInfo
   * @constructor
   */
  SET_SHOP_INFO: (state, shopInfo) => {
    state.shop = shopInfo;
    geLoginType().then(resp => {
      if (resp) {
        sessionStorage.setItem('from_enter_seller_shop', JSON.stringify(shopInfo));
      } else {
        Storage.setItem('seller_shop', shopInfo);
      }
      sessionStorage.setItem('shopInfo2021', JSON.stringify(shopInfo));
      Storage.setItem('shopInfo2021', JSON.stringify(shopInfo));
    });
    // // 清除url上的参数
    // const params = location.href.split('?');
    // if (params[1]&&params[1].includes('tokens')) location.replace(params[0]);
  },
  /**
   * 移除店铺信息
   * @param state
   * @constructor
   */
  REMOVE_SHOP_INFO: (state) => {
    state.shop = '';
    Storage.removeItem('seller_shop');
  },
  SET_IS_SUPPLIER_NAME: (state, val) => {
    state.isSupplierName = val === 'OPEN';
  },
  CLEARCACHE(){
    Vue.ls.clear();
  }
}

// actions
export const actions = {
  /**
   * token登陆
   */

  loginToken({commit}, token) {
    // tokenLogin
    return new Promise((resolve, reject) => {
      API_Login.tokenLogin(token).then(response => {
        commit('TOKEN_SET_USER', response);
        setTimeout(() => {
          getShopData().then(resp => {
            commit('SET_SHOP_INFO', resp);
            resolve();
          });
        });
      }).catch(reject);
    })
  },

  /**
   * 用户名登录
   * @param commit
   * @param params
   * @returns {Promise<any>}
   */
  loginAction({commit}, params) {
    return new Promise((resolve, reject) => {
      API_Login.login(params).then(response => {
        const {
          access_token,
          refresh_token,
        } = response;

        const expires = new Date(jwt_decode(refresh_token).exp * 1000)

        commit('SET_USER', {
          ...response,
          expires
        });
        commit('SET_ACCESS_TOKEN', access_token);
        commit('SET_REFRESH_TOKEN', refresh_token);
        window.userInfo = response
        broadcast.postMessage(MESSAGE_TYPES.ACCOUNT_CHANGED, response);
        resolve();
      }, reject);
    })
  },
  /**
   * 手机号登录
   * @param {*} commit 
   * @param {*} params 
   */
  loginDropShippingAction ({ commit },params) {
    return new Promise(async (resolve, reject) => {
    try {
      const response = await API_Login.loginDropShipping(params)
      const { access_token, refresh_token, } = response;
      const expires = new Date(jwt_decode(refresh_token).exp * 1000)
      commit('SET_USER', { ...response, expires });
      commit('SET_ACCESS_TOKEN', access_token);
      commit('SET_REFRESH_TOKEN', refresh_token);
      window.userInfo = response
      broadcast.postMessage(MESSAGE_TYPES.ACCOUNT_CHANGED, response);
      resolve(response);
    } catch (error) {
      reject(error)
    }
    })
  },

  smscodeDropShippingAction ({ commit },params) {
    return new Promise(async (resolve, reject) => {
  
      try {
        const response = await API_Login.smscodeDropShipping(params)
      resolve(response)
      } catch (error) {
        reject(error)
      }

      })
  },

  /**
   * 获取店铺信息
   * @param commit
   * @returns {Promise<any>}
   */
  getShopInfoAction({commit}) {
    return new Promise((resolve, reject) => {
      API_Shop.getShopData().then(response => {
        commit('SET_SHOP_INFO', response)
        resolve(response)
      }).catch(reject)
    })
  },
  /**
   * 登出
   * @param dispatch
   * @returns {Promise<any>}
   */
  logoutAction({dispatch}) {
    return new Promise((resolve, reject) => {
      API_Login.logout().then(() => {
        // 刷新浏览器，企业跳转到站点应该获取站点的用户信息而非cookie里面的seller_user
        sessionStorage.clear();
        dispatch('fedLogoutAction')
        resolve()
      }).catch(reject)
    })
  },
  /**
   * 设置访问令牌
   * @param commit
   * @param token
   */
  setAccessTokenAction({commit}, token) {
    commit('SET_ACCESS_TOKEN', token)
  },
  /**
   * 设置刷新令牌
   * @param commit
   * @param token
   */
  setRefreshTokenAction({commit}, token) {
    commit('SET_REFRESH_TOKEN', token)
  },
  /**
   * 前端退出
   * @param commit
   */
  fedLogoutAction: ({commit}) => {
    commit('REMOVE_USER')
    commit('REMOVE_SHOP_INFO')
    commit('REMOVE_ACCESS_TOKEN')
    commit('REMOVE_REFRESH_TOKEN')
    commit('CLEARCACHE')
  },
  // 设置是否显示供应商名称
  setIsSupplierName: ({commit}, params) => {
    commit('SET_IS_SUPPLIER_NAME', params)
  }

}
