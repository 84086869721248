<template>
    <el-dialog-x-dialog title="选择商家" :visible.sync="dialogVisible" :close-on-click-modal="false" :close-on-press-escape="false" append-to-body width="805px">
        <div class="goods-picker-body">
          <h3 v-if="shopType.length > 0" style="margin-left: 20px;">提示：挑选礼包时，若礼包内商品在目标商城中不存在，系统会将礼包内商品一并挑选到目标商城。</h3>
            <div class="goods-category">
                <dl>
                    <dt>商家应用类型</dt>
                    <div class="kj">
                        <dd v-for="(item,index) in storeTypeList" :class="{'activeClass':index == activeCategoryIndex1}" @click="handleSelectCategory(item, index, 1)">
                            <span>{{ item.name }}</span> <span>》</span>
                        </dd>
                    </div>
                </dl>
                <span style="display: inline-block;margin-top: 200px;">》</span>
                <dl>
                    <dt>选择商家</dt>
                    <div class="kj">
                        <dd v-for="(item,index) in storeList" :class="{'activeClass':index == activeCategoryIndex2}" @click="handleSelectCategory(item,index, 2)">
                            <span>{{ item.shop_name }}</span>
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
        <div class="goods-picker-footer" style="float: right; margin-bottom: 20px;">
            <span>
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleConfirm">确 定</el-button>
            </span>
        </div>
    </el-dialog-x-dialog>
</template>
<script>
import mixin from './mixin'

export default {
  name: 'EnStorePicker',
  mixins: [mixin]
}
</script>
<style type="text/scss" lang="scss" scoped>
@import "./styles";
</style>
<style type="text/scss" lang="scss">
.params-ids-select-dropdown {
  display: none !important;
}
</style>
<style scoped>
.goods-category {
  text-align: center;
}

.kj {
  height: 330px;
  overflow: auto;
}
h3{
  margin: 0;
  text-align: center;
}
.goods-category dl {
    width: 30%;
    display: inline-block;
    margin: 2%;
    border:1px solid rgba(230,236,247,1);
    vertical-align: top;
    border-radius: 5px;

}

.goods-category dd {
  margin-inline-start: 0;
  line-height: 35px;
  border-top: 1px solid #efefef;
  cursor: pointer;
  font-size: 14px;
}

.goods-category dd.activeClass {
  color: #666666;
  background: rgba(238, 240, 245, 1);
}

.goods-category dt {
  font-size: 15px;
  color: white;
  border-radius: 5px 5px 0 0;
  line-height: 40px;
  background: rgba(26, 67, 169, 1);
}
</style>