<template>
  <div class="content-goods-publish">
    <div class="goods-category">
      <ul v-if="categoryListLevel1 && categoryListLevel1.length > 0">
        <li v-for="(item, index) in categoryListLevel1" :class="{ activeClass: index == activeCategoryIndex1 }"
          :key="index" @click="handleSelectCategory(item, index, 1)">
          <span>{{ item.name }}</span>
          <!-- 佣金 -->
          <p v-if="item.edit" class="commission">分销佣金比例：
            <el-input
            onblur="if(!/^(?!0{2,})(\d{1,2}|\d{1,2}\.\d{1,2})$/.test(value)&&value!=100) value=''"
            oninput="if(!/^(?!0{2,})(\d{1,2}|\d{1,2}\.\d{1,2})$/.test(value)) value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');if(value>100)value=100;if(value<0)value=null;if((value[0] == 0 && value[1] > 0) || value == '00')value=value.slice(1);"
             size="mini" style="width:100px" placeholder="" v-model.trim="item.commission" clearable >
              <template slot="append">%</template>
            </el-input>
          </p>
          <span v-else class="commissionName">{{ item.commission?item.commission+'%':'' }}</span>
          <span>》</span>
        </li>
      </ul>
      <ul v-if="categoryListLevel2[level2Id] && categoryListLevel2[level2Id].length > 0">
        <li v-for="(item, index) in categoryListLevel2[level2Id]" :class="{ activeClass: index == activeCategoryIndex2 }"
          @click="handleSelectCategory(item, index, 2)" :key="index">
          <span>{{ item.name }}</span>
           <!-- 佣金 -->
           <p v-if="item.edit" class="commission">分销佣金比例：
            <el-input
            onblur="if(!/^(?!0{2,})(\d{1,2}|\d{1,2}\.\d{1,2})$/.test(value)&&value!=100) value=''"
            oninput="if(!/^(?!0{2,})(\d{1,2}|\d{1,2}\.\d{1,2})$/.test(value)) value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');if(value>100)value=100;if(value<0)value=null;if((value[0] == 0 && value[1] > 0) || value == '00')value=value.slice(1);"
            size="mini" style="width:100px" placeholder="" v-model.trim="item.commission" clearable >
              <template slot="append">%</template>
            </el-input>
          </p>
          <span v-else class="commissionName">{{ item.commission?item.commission+'%':'' }}</span>
          <span>》</span>
        </li>
      </ul>
      <ul v-if="categoryListLevel3[level3Id] && categoryListLevel3[level3Id].length > 0">
        <li v-for="(item, index) in categoryListLevel3[level3Id]" :class="{ activeClass: index == activeCategoryIndex3 }"
          @click="handleSelectCategory(item, index, 3)" :key="index">
          <span>{{ item.name }}</span>
           <!-- 佣金 -->
           <p v-if="item.edit" class="commission">分销佣金比例：
            <el-input
            onblur="if(!/^(?!0{2,})(\d{1,2}|\d{1,2}\.\d{1,2})$/.test(value)&&value!=100) value=''"
            oninput="if(!/^(?!0{2,})(\d{1,2}|\d{1,2}\.\d{1,2})$/.test(value)) value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');if(value>100)value=100;if(value<0)value=null;if((value[0] == 0 && value[1] > 0) || value == '00')value=value.slice(1);"
            size="mini" style="width:100px" placeholder="" v-model.trim="item.commission" clearable >
              <template slot="append">%</template>
            </el-input>
          </p>
          <span v-else class="commissionName">{{ item.commission?item.commission+'%':'' }}</span>
          <span>》</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import * as API_goodsCategory from "@/api/goodsCategory";
export default {
  name: 'ElClassification',
  props: {
    /** 映射属性 */
    parentData: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
     /** 1级分类列表*/
     categoryListLevel1: [],
      /** 2级分类列表*/
      categoryListLevel2: {},
      level2Id: 0,
      level3Id: 0,
      /** 3级分类列表*/
      categoryListLevel3:{},
      // 的1级分类索引
      activeCategoryIndex1: -1,
      /** 当前点击的2级分类索引*/
      activeCategoryIndex2: -1,
      /** 当前点击的3级分类索引*/
      activeCategoryIndex3: -1,
      baseInfoForm: {
        category_id: ""
      }
    }
  },
  mounted() {
    this.GET_NextLevelCategory();
  },
  activated() {
    this.GET_NextLevelCategory();
  },
  methods: {
    /**监听分销比例变更 */
    handleCommission (row, index) {
      console.log(row, index);
    },
    /** 搜索下一级 商城商品分类*/
    GET_NextLevelCategory (row, level) {
      this.loading = true;
      const _id = row && row.category_id !== 0 ? row.category_id : 0;
      API_goodsCategory.getGoodsCategoryLevelList(_id, {}).then((response) => {
        response.forEach(element => {
          element.edit = false;
          element.commission = ''
        });
        this.loading = false;
        if (level === 1) {//点击一级分类
          
          this.categoryListLevel1.forEach(element => {
           
            if (row && row.category_id !== 0) {
              element.category_id==row.category_id?element.edit=true:element.edit=false
            }
          });
          
          if ( `${row.category_id}` in this.categoryListLevel2) {
            
          } else {
            this.$set(this.categoryListLevel2, row.category_id, response)
            const parentDataMap = new Map(this.parentData.map(e => [e.category_id, e]));
            this.categoryListLevel2[row.category_id].forEach(element => {
              const parentData = parentDataMap.get(element.category_id);
              if (parentData&&element.commission=='') {
                element.commission = parentData.value;
              }
            });
            
          }
          this.level2Id = row.category_id;
          this.categoryListLevel2[this.level2Id].forEach(element => {
            if (row && row.category_id !== 0) {
              element.category_id==row.category_id?element.edit=true:element.edit=false
            }
          });
        } else if (level === 2) {//点击二级分类
          this.categoryListLevel2[this.level2Id].forEach(element => {
            if (row && row.category_id !== 0) {
              element.category_id==row.category_id?element.edit=true:element.edit=false
            }
          });
          if ( `${row.category_id}` in this.categoryListLevel3) {
            
          } else {
            this.$set(this.categoryListLevel3, row.category_id, response)
            const parentDataMap = new Map(this.parentData.map(e => [e.category_id, e]));
            this.categoryListLevel3[row.category_id].forEach(element => {
              const parentData = parentDataMap.get(element.category_id);
              if (parentData&&element.commission=='') {
                element.commission = parentData.value;
              }
            });
          }
          
          this.level3Id = row.category_id;
          this.categoryListLevel3[this.level3Id].forEach(element => {
            if (row && row.category_id !== 0) {
              element.category_id==row.category_id?element.edit=true:element.edit=false
            }
          });
        } else if (level === 3) {//点击三级分类
          this.categoryListLevel3[this.level3Id].forEach(element => {
          if (row&& row.category_id !== 0) {
            element.category_id==row.category_id?element.edit=true:element.edit=false
          }
          });
        } else if (!level) {//初始化
          this.categoryListLevel1 = response;
          const parentDataMap = new Map(this.parentData.map(e => [e.category_id, e]));
          this.categoryListLevel1.forEach(element => {
            const parentData = parentDataMap.get(element.category_id);
            if (parentData) {
              element.commission = parentData.value;
            }
            if (row&& row.category_id !== 0) {
              element.category_id==row.category_id?element.edit=true:element.edit=false
            }
          });
        }
      });
    },
    /**父组件获取子组件所有设置分销比例的分类 */
    goOutform () {
      let mergedArray = [];
      this.categoryListLevel1.forEach(element => {
        console.log(element.commission,333333);
        if (element.commission != '') {
          mergedArray.push(element)
        }
      });

      for (const key in this.categoryListLevel2) {
        if (Object.hasOwnProperty.call(this.categoryListLevel2, key)) {
          const element = this.categoryListLevel2[key];
          element.forEach(element => {
            if (element.commission!='') {
              mergedArray.push(element)
            }
          });
        }
      }
      for (const key in this.categoryListLevel3) {
        if (Object.hasOwnProperty.call(this.categoryListLevel3, key)) {
          const element = this.categoryListLevel3[key];
          element.forEach(element => {
            if (element.commission!='') {
              mergedArray.push(element)
            }
          });
        }
      }
      return mergedArray;
    },
    /** 选择商城商品分类 */
    handleSelectCategory (row, index, level) {
      if (level === 1) {//一级分类
        this.activeCategoryIndex1 = index;
        this.activeCategoryIndex2 = -1;
        this.activeCategoryIndex3 = -1;
      } else if (level === 2) {//二级分类
        this.activeCategoryIndex2 = index;
        this.activeCategoryIndex3 = -1;
      } else {//三级分类
        this.activeCategoryIndex3 = index;
      }
      // 设置当前商城分类ID
      this.baseInfoForm.category_id = row.category_id;
      if (row) {
          row.edit = true;
        }
      this.GET_NextLevelCategory(row, level);
    },

  }
}
</script>
<style lang="scss" scoped>
/*选择商品品类*/
.content-goods-publish {
  // padding: 15px;
  margin: 0 auto;
  text-align: center;
  // border: 1px solid #ddd;
  // background: none repeat 0 0 #fff;
  // padding-bottom: 45px;

  /*商品品类*/
  .goods-category {
    text-align: left;
    padding: 10px;
    // background: #fafafa;
    // border: 1px solid #e6e6e6;

    ul {
      padding: 8px 4px 8px 8px;
      list-style: none;
      width: 320px;
      background: none repeat 0 0 #fff;
      border: 1px solid #e6e6e6;
      display: inline-block;
      letter-spacing: normal;
      margin-right: 15px;
      vertical-align: top;
      word-spacing: normal;
      height: 400px;
      overflow-y: auto;
      box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);

      li {
        line-height: 20px;
        padding: 5px;
        cursor: pointer;
        color: #333;
        font-size: 12px;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: relative;
      }
    }
  }

  /** 当前品类被选中的样式 */
  .activeClass {
    background-color: #d9edf7;
    border: 1px solid #bce8f1;
    color: #3a87ad;
  }

  /*!*当前选择的商品品类文字*!*/
  .current-goods-category {
    text-align: left;
    padding: 10px;
    width: 100%;
    border: 1px solid #fbeed5;
    color: #c09853;
    background-color: #fcf8e3;
    margin: 10px auto;
    padding: 8px 35px 8px 14px;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    font-size: 12px;
    font-weight: bold;
  }
}
.commission{
  display: flex;
  align-items: center;
  margin: 0;
  position: absolute;
  right: 22px;
}
.commissionName{
  width: 50px;
  position: absolute;
  right: 0;
}
/deep/ .el-input-group__append, .el-input-group__prepend{
  padding: 0 10px;
}
</style>
