import Vue from "vue";
import Router from "vue-router";
import Layout from "@/views/layout/Layout";
import comboCardShopRouterMap from "@/router/asyncRouterMap/combo-card-shop";

Vue.use(Router);

export const constantRouterMap = [
  {
    path: "/404",
    component: () => import("@/views/errorPage/404"),
    hidden: true,
  },
  {
    path: "/login",
    component: () => import("@/pages/auth/pages/login2"),
    hidden: true,
  },
  {
    path: "/no-sale-open",
    component: () => import("@/views/errorPage/no-sale-open"),
    name: "noSaleOpen",
    hidden: true,
  },
  {
    path: "/hub",
    component: () => import("@/views/Hub"),
    name: "Hub",
    hidden: true,
  },
  // 开发-管理后台菜单使用,已在左侧菜单隐藏
  {
    path: "/development",
    component: Layout,
    redirect: "/development/tool-manage/menu-manage",
    name: "development",
    meta: {
      title: "development",
      icon: "page",
    },
    hidden: true,
    children: [
      {
        path: "/development/tool-manage",
        component: () => import("@/views/development/tool-manage/index"),
        redirect: "/development/tool-manage/menu-manage",
        name: "toolManage",
        meta: { title: "toolManage" },
        children: [
          {
            path: "menu-manage",
            component: () =>
              import("@/views/development/tool-manage/menuManage"),
            name: "menuManage",
            meta: { title: "menuManage" },
          },
        ],
      },
    ],
  },
];

export const asyncRouterMap = [
  {
    path: "/",
    component: Layout,
    redirect: "/dashboard",
    meta: {
      title: "sencond",
      icon: "home",
    },
    name: "sencond",
    children: [
      {
        path: "dashboard",
        component: () => import("@/views/dashboard/index"),
        name: "dashboard",
        meta: { title: "sencond" },
      },
    ],
  },
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    name: "first",
    meta: {
      title: "first",
      icon: "home",
    },
    children: [
      {
        path: "home",
        component: () => import("@/views/dashboard/home"),
        name: "home",
        meta: { title: "home" },
      },
      {
        path: "loginLog",
        component: () => import("@/views/log/loginLog"),
        name: "loginLog",
        meta: { title: "loginLog" },
      },
      {
        path: "loginLoga",
        component: () => import("@/views/log/loginLog"),
        name: "loginLoga",
        meta: { title: "loginLoga" },
      },
      {
        path: "handle",
        component: () => import("@/views/log/handle"),
        name: "handleLog",
        meta: { title: "handleLog" },
      },
      {
        path: "handlea",
        component: () => import("@/views/log/handle"),
        name: "handleLoga",
        meta: { title: "handleLoga" },
      },
      {
        path: "compromote",
        component: () => import("@/views/log/comPromote"),
        name: "compromote",
        meta: { title: "compromote" },
      },
    ],
  },

  // 页面管理
  {
    path: "/",
    component: Layout,
    redirect: "/floor",
    name: "page",
    meta: {
      title: "page",
      icon: "page",
    },
    children: [
      {
        path: "floor-preload",
        // component: () => import('@/views/page/mobile-decoration/mobileFloorManage'),
        component: () => import("@/pages/floor/floor-preload"),
        name: "mobileFloorManageA",
        meta: { title: "mobileFloorManageA" },
      },
      {
        path: "floor-preload/:type/:id",
        component: () => import("@/pages/floor/floor-preload"),
        hidden: true,
      },
      {
        path: "floor",
        component: () => import("@/pages/floor/renovation/floor-renovation"),
        name: "floor",
        hidden: true,
      },
      {
        path: "floor/:type/:id",
        component: () => import("@/pages/floor/renovation/floor-renovation"),
        name: "floorWidthParams",
        hidden: true,
      },
      {
        path: "child",
        component: () => import("@/views/page/page-setting/childFloor"),
        name: "childFloor",
        meta: { title: "childFloor" },
      },
      {
        path: "special",
        component: () => import("@/views/page/special/list"),
        name: "specialList",
        meta: { title: "specialList" },
      },
      {
        path: "loginJ",
        component: () => import("@/views/page/journal/loginJournal"),
        name: "loginJournal",
        meta: { title: "loginJournal" },
      },
      {
        path: "operation",
        component: () => import("@/views/page/journal/operationJournal"),
        name: "operationJournal",
        meta: { title: "operationJournal" },
      },
      {
        path: "special-edit",
        component: () => import("@/views/page/special/edit"),
        name: "specialEdit",
        meta: { title: "specialEdit" },
        hidden: true,
      },
      {
        path: "focus",
        component: () =>
          import("@/views/page/mobile-decoration/mobileFocusManage"),
        name: "mobileFocusManage",
        meta: { title: "mobileFocusManage" },
      },
      {
        path: "poverty-alleviation",
        component: () =>
          import("@/views/page/mobile-decoration/povertyAlleviation"),
        name: "povertyAlleviationWap",
        meta: { title: "povertyAlleviationWap" },
      },
      {
        path: "/page/page-setting",
        component: () => import("@/views/page/page-setting/index"),
        redirect: "/page/page-setting/pc-site-menu",
        name: "pageSetting",
        meta: { title: "pageSetting" },
        children: [
          // {
          //   path: 'pc-site-menu',
          //   component: () => import('@/views/page/page-setting/pcSiteMenu'),
          //   name: 'pcSiteMenu',
          //   meta: {title: 'pcSiteMenu'}
          // },
          {
            path: "mobile-site-menu",
            component: () => import("@/views/page/page-setting/mobileSiteMenu"),
            name: "mobileSiteMenu",
            meta: { title: "mobileSiteMenu" },
          },
          {
            path: "hot-keyword",
            component: () => import("@/views/page/page-setting/hotKeyword"),
            name: "hotKeyword",
            meta: { title: "hotKeyword" },
          },
        ],
      },
    ],
  },
  // 集市
  {
    path: "/markets",
    component: Layout,
    redirect: "/markets/market",
    name: "markets",
    meta: {
      title: "markets",
      icon: "market",
    },
    children: [
      {
        path: "market",
        component: () => import("@/views/page/pc-decoration/market"),
        name: "market",
        meta: { title: "market" },
      },
      {
        path: "goods",
        component: () => import("@/views/page/pc-decoration/goods"),
        name: "marketGoods",
        hidden: true,
        meta: { title: "marketGoods" },
      },

      {
        path: "shopMarket",
        component: () => import("@/views/page/pc-decoration/goods"),
        name: "shopMarket",
        hidden: true,
        meta: { title: "marketGoods" },
      },
      { // 企业端添加商品
        path: "publish",
        component: () =>
          import("@/views/page/pc-decoration/privateGoodsPublish"),
        name: "privateGoodsPublish",
        hidden: true,
        meta: { title: "privateGoodsPublish" },
      },
      {
        path: "good",
        component: () => import("@/views/page/pc-decoration/good"),
        name: "marketGood",
        hidden: true,
        meta: { title: "marketGood" },
      },
      {
        path: "gooda",
        component: () => import("@/views/page/pc-decoration/good"),
        name: "marketGooda",
        hidden: true,
        meta: { title: "marketGooda" },
      },
      {
        path: "goodb",
        component: () => import("@/views/page/pc-decoration/good"),
        name: "marketGoodb",
        hidden: true,
        meta: { title: "marketGoodb" },
      },
      {
        path: "privateGoods/:nav/:type",
        component: () => import("@/views/page/pc-decoration/privateGoods"),
        name: "privateGoods",
        hidden: true,
        meta: {
          title: "privateGoods",
          activeNames: ["market"],
        },
      },
      { // 供应商端录入商品
        path: "good-edit",
        component: () => import("@/views/goods/goodsPublish"),
        name: "goodEdit1",
        meta: {
          title: "goodEdit",
          noCache: true,
        },
        hidden: true,
      },
    ],
  },
  // 商品管理（供应商）
  {
    path: "/goods",
    component: Layout,
    redirect: "/goods/goods-list",
    name: "goods",
    meta: {
      title: "goods",
      icon: "goods",
    },
    children: [
      {
        path: "goods-list",
        component: () => import("@/pages/goods/pages/goods-list/goods-list"),
        name: "goodsList",
        meta: { title: "goodsList" },
      },
      {
        path: "good-publish",
        component: () => import("@/views/goods/goodsPublish"),
        name: "goodPublish",
        hidden: true,
        meta: {
          title: "goodPublish",
          noCache: true,
          activeNames: ["goodsList"],
        },
      },
      {// 供应商端录入商品
        path: "good-edit",
        component: () => import("@/views/goods/goodsPublish"),
        name: "goodEdit",
        meta: {
          title: "goodEdit",
          noCache: true,
          activeNames: ["goodsList"],
        },
        hidden: true,
      },
      {// 京东供应商录入商品
        path: "good-edit-jd",
        component: () => import("@/views/goods/goodsPublishJD"),
        name: "goodEditJD",
        meta: {
          title: "goodEditJD",
          noCache: true,
          activeNames: ["goodsList"],
        },
        hidden: true,
      },
      {// 蛋糕供应商录入商品
        path: "good-edit-cake",
        component: () => import("@/views/goods/goodsPublishCake"),
        name: "goodEditCake",
        meta: {
          title: "goodEditCake",
          noCache: true,
          activeNames: ["goodsList"],
        },
        hidden: true,
      },
      {
        path: "goods-change",
        component: () => import("@/views/goods/goodsChange"),
        name: "goodsChange",
        meta: { title: "goodsChange" },
      },
      {
        path: "goods-import-list",
        component: () => import("@/views/goods/goodsImportList"),
        name: "goodsImportList",
        meta: {
          title: "goodsImportList",
        },
      },
      {
        path: "draft-list",
        component: () => import("@/views/goods/draftList"),
        name: "draftList",
        meta: { title: "draftList" },
        hidden: true,
      },
      // { path: 'category-manage', component: () => import('@/views/goods/categoryManage'), name: 'categoryManage', meta: { title: 'categoryManage' }},
      {
        path: "tag-manage",
        component: () => import("@/views/goods/tagManage"),
        name: "tagManage",
        meta: { title: "tagManage" },
        hidden: true,
      },
      {
        path: "recycle-station",
        component: () => import("@/views/goods/recycleStation"),
        name: "recycleStation",
        meta: { title: "recycleStation" },
        hidden: true,
      },
      {
        path: "understock",
        component: () => import("@/views/goods/understock"),
        name: "understock",
        meta: { title: "understock" },
        hidden: true,
      },
      {
        path: "good-publish/:goodsid?/:isdraft?",
        component: () => import("@/views/goods/goodsPublish"),
        name: "goodPublish",
        meta: {
          title: "goodPublish",
          noCache: true,
        },
        hidden: true,
      },
      {
        path: "tag-add/:tag_id",
        component: () => import("@/views/goods/tagAdd"),
        name: "tagAdd",
        meta: { title: "tagAdd" },
        hidden: true,
      },
    ],
  },
  // 商品管理（商家）
  {
    path: "/shop-goods",
    component: Layout,
    redirect: "/shop-goods/shop-goods-list",
    name: "shopGoods",
    meta: {
      title: "shopGoods",
      icon: "goods",
    },
    children: [
      {
        path: "shop-goods-list",
        component: () => import("@/views/shopGoods/shopGoodsList"),
        name: "shopGoodsList",
        meta: { title: "shopGoodsList" },
      },
      {
        path: "goodsListzd",
        component: () => import("@/views/shopGoods/goodsListzd"),
        name: "goodsListzd",
        meta: { title: "goodsListzd" },
        hidden: true,
      },
      {
        path: "market",
        component: () => import("@/views/shopGoods/market"),
        name: "market1",
        meta: { title: "market1" },
      },
      {
        path: "goods",
        component: () => import("@/views/shopGoods/goods"),
        name: "shopGoodsa",
        hidden: true,
        meta: { title: "shopGoodsa" },
      },
      {
        path: "goods/:nav/:type",
        component: () => import("@/views/shopGoods/goods"),
        name: "shopGoodsa",
        hidden: true,
        meta: { title: "shopGoodsa", activeNames: ["market1"] },
      },
      {
        path: "good/:from",
        component: () => import("@/views/page/pc-decoration/good"),
        name: "marketGooda",
        hidden: true,
        meta: { title: "marketGooda" },
      },
      {
        path: "intel-operation",
        component: () => import("@/views/shopGoods/intelOperation"),
        name: "intelOperation",
        meta: {
          title: "intelOperation",
        },
      },
      { // 企业端添加商品
        path: "publish",
        component: () =>
          import("@/views/page/pc-decoration/privateGoodsPublish"),
        name: "privateGoodsPublish",
        hidden: true,
        meta: { title: "privateGoodsPublish" },
      },
      // {
      //   path: 'programmeList',
      //   component: () => import('@/views/shopGoods/programmeList'),
      //   name: 'programmeList',
      //   meta: { title: 'programmeList' }
      // },
      // {
      //   path: 'programmeRecord',
      //   component: () => import('@/views/shopGoods/programmeRecord'),
      //   name: 'programmeRecord',
      //   meta: { title: 'programmeRecord' }
      // },
      // {
      //   path: 'programmeDetail/:id',
      //   component: () => import('@/views/shopGoods/programmeDetail'),
      //   name: 'programmeDetail',
      //   hidden: true,
      //   meta: { title: 'programmeDetail' }
      // },
      // { path: 'tag-manage', component: () => import('@/views/goods/tagManage'), name: 'tagManages', meta: { title: 'tagManages', shop: true }},
      {
        path: "tag-add/:tag_id",
        component: () => import("@/views/goods/tagAdd"),
        name: "shopTagAdd",
        meta: { title: "shopTagAdd" },
        hidden: true,
      },
      {
        path: "shop-good-publish/:goodsid?/:isdraft?",
        component: () => import("@/views/shopGoods/shopGoodPublish"),
        name: "shopGoodPublish",
        meta: {
          title: "shopGoodPublish",
          noCache: true,
        },
        hidden: true,
      },
      {
        path: "price-change-record",
        name: "PriceChangeRecord",
        component: () =>
          import("@/pages/goods/pages/PriceChangeRecord/PriceChangeRecord"),
        meta: { title: "PriceChangeRecord" },
      },
    ],
  },

  // 2.0 之前的用户路由
  {
    path: "/users",
    component: Layout,
    name: "users",
    redirect: "/users/user-list",
    meta: {
      title: "users",
      icon: "user",
    },
    children: [
      {
        path: "user-list",
        component: () => import("@/views/users/userList"),
        name: "userList",
        meta: { title: "userList" },
      },
      {
        path: "fzadmin",
        component: () => import("@/views/users/fzadmin"),
        name: "fzadmin",
        meta: { title: "fzadmin" },
      },
      {
        path: "userschong",
        component: () => import("@/views/users/userschong"),
        name: "userschong",
        meta: { title: "userschong" },
      },
      {
        path: "user-msg",
        component: () => import("@/views/users/usersMsg"),
        name: "usersMsg",
        hidden: true,
        meta: {
          title: "usersMsg",
          activeNames: ["userList"],
        },
      },
      {
        path: "chongMsg",
        component: () => import("@/views/users/chongMsg"),
        name: "chongMsg",
        hidden: true,
        meta: {
          title: "chongMsg",
          activeNames: ["userschong"],
        },
      },
    ],
  },

  // 订单管理
  {
    path: "/order",
    component: Layout,
    name: "order",
    redirect: "/order/order-list",
    meta: {
      title: "order",
      icon: "order",
    },
    children: [
      // 供应商
      {
        path: "union-order-list",
        component: () => import("@/views/order/unionOrdertList"),
        name: "unionOrderList",
        meta: { title: "unionOrderList" },
      },
      {
        path: "union-refund-list",
        component: () => import("@/views/order/unionRefundList/"),
        name: "unionRefundList",
        meta: { title: "unionRefundList" },
      },
      {
        path: "union-export-list",
        component: () => import("@/views/order/unionExportList/"),
        name: "unionExportList",
        meta: { title: "unionExportList" },
      },
      // 企业端-订单列表
      {
        path: 'order-list',
        component: () => import('@/views/order/orderList/orderList'),
        name: 'enterpriseOrderList',
        meta: { title: 'orderList' },
        // redirect: '/order/order-list/shop-order-list',
        // children: [
        //   {
        //     path: 'shop-order-list',
        //     name: 'enterpriseOrderList',
        //     component: () => import('@/views/order/orderList/orderList'),
        //     meta: {
        //       title: 'shopOrderList'
        //     }
        //   },
        //   {
        //     path: 'combo-order-list',
        //     name: 'comboOrderList',
        //     component: () => import('@/views/order/orderList/orderList'),
        //     meta: {
        //       title: 'comboOrderList'
        //     }
        //   },
        //   {
        //     path: 'ai-combo-order-list',
        //     name: 'aiComboOrderList',
        //     component: () => import('@/views/order/orderList/aiComboOrderList'),
        //     meta: {
        //       title: 'aiComboOrderList'
        //     }
        //   },
        //   // 智能套餐卡订单详情
        //   {
        //     path: 'ai-combo-order-detail/:sn',
        //     name: 'aiComboOrderDetail',
        //     component: () => import('@/views/order/orderList/aiComboOrderDetail'),
        //     hidden: true,
        //     meta: {
        //       title: 'aiComboOrderDetail',
        //       activeNames: ['aiComboOrderList']
        //     }
        //   },
        //   {
        //     path: ':route/detail/:sn',
        //     component: () => import('@/views/order/orderList/orderDetail'),
        //     name: 'orderDetail',
        //     hidden: true,
        //     meta: {
        //       title: 'orderDetail',
        //       activeNames: [],
        //     }
        //   },
        // ]
      },
      // 现金商城-订单列表
      {
        path: "order-list",
        component: () => import("@/views/order/orderList/orderList"),
        name: "orderList",
        meta: { title: "orderList" },
      },
      // 现金商城-维权订单
      {
        path: "refund-list",
        component: () => import("@/views/order/refundList/refundList"),
        name: "refundList",
        meta: { title: "refundList" },
      },
      // 企业端-维权订单
      {
        path: 'refund-list',
        component: () => import('@/views/order/refundList/refundList'),
        name: 'enterpriseRefundList',
        meta: { title: 'enterpriseRefundList' },
        // children: [
        // {
        //   path: 'shop-refund-list',
        //   component: () => import('@/views/order/refundList/refundList'),
        //   name: 'enterpriseRefundList',
        //   meta: {
        //     title: 'shopRefundList'
        //   }
        // },
        // {
        //   path: 'combo-refund-list',
        //   component: () => import('@/views/order/refundList/refundList'),
        //   name: 'comboRefundList',
        //   meta: {
        //     title: 'comboRefundList'
        //   }
        // },
        // {
        //   path: 'ai-combo-refund-list',
        //   component: () => import('@/views/order/refundList/aiComboRefundList'),
        //   name: 'aiComboRefundList',
        //   meta: {
        //     title: 'aiComboRefundList'
        //   }
        // },
        //   {
        //     path: 'ai-combo-refund-detail/:sn',
        //     name: 'aiComboRefundDetail',
        //     hidden: true,
        //     component: () => import('@/views/order/refundList/aiComboRefundDetail'),
        //     meta: {
        //       title: 'aiComboRefundDetail',
        //       activeNames: ['aiComboRefundList']
        //     }
        //   }
        // ]
      },
      {
        path: 'groupBuying-List', // 企业端拼团订单
        component: () => import('@/views/order/groupBuyingList/groupBuyingList'),
        name: 'activityGroupBuyOrder',
        meta: {
          title: 'groupBuyingList'
        }
      },
      {
        path: 'groupBuyingDetail/:sn?', // 企业端拼团订单详情
        component: () => import('@/views/order/groupBuyingList/groupBuyingDetail'),
        name: 'groupBuyingDetail',
        hidden: true,
        meta: {
          title: 'groupBuyingDetail',
          noCache: true,
          activeNames: ['activityGroupBuyOrder']
        }
      },
      // 企业端-服务订单
      {
        path: "serve-list",
        component: () => import("@/views/order/serverList"),
        name: "serverList",
        meta: { title: "serverList" },
      },
      {
        path: "coupon-order-list",
        component: () => import("@/views/order/couponOrderList"),
        name: "couponOrderList",
        meta: { title: "couponOrderList" },
      },
      {
        path: "supplement-list",
        name: "supplementList",
        component: () => import("@/views/order/supplement/supplementList"),
        meta: { title: "supplementList" },
      },
      {
        path: "supplement-detail/:sn",
        name: "supplementDetail",
        component: () => import("@/views/order/supplement/supplementDetail"),
        meta: { title: "supplementDetail", activeNames: ["supplementList"] },
        hidden: true,
      },
      {
        path: "coupon-order-detail/:id",
        component: () => import("@/views/order/couponOrderDetail"),
        name: "couponOrderDetail",
        meta: {
          title: "couponOrderDetail",
          noCache: true,
          activeNames: ["couponOrderList"],
        },
        hidden: true,
      },
      {
        path: "refund-detail/:sn",
        component: () => import("@/views/order/refundList/refundDetail"),
        name: "refundDetail",
        meta: {
          title: 'refundDetail',
          activeNames: ['refundList', 'enterpriseRefundList'],
        },
        hidden: true,
      },
      // 现金商城订单详情
      {
        path: ":route/detail/:sn",
        component: () => import("@/views/order/orderList/orderDetail"),
        name: "orderDetail",
        hidden: true,
        meta: {
          title: 'orderDetail',
          noCache: true,
          activeNames: ['orderList', 'enterpriseOrderList'],
        }
      },
      // 供应商维权订单详情
      {
        path: "union-refund-detail/:sn",
        component: () => import("@/views/order/unionRefundDetail"),
        name: "unionRefundDetail",
        meta: { title: "unionRefundDetail", activeNames: ["unionRefundList"] },
        hidden: true,
      },
      // 供应商订单详情
      {
        path: "union-order-detail/:sn",
        component: () => import("@/views/order/unionOrderDetail"),
        name: "unionOrderDetail",
        hidden: true,
        meta: {
          title: 'unionOrderDetail',
          activeNames: ['unionOrderList'],
        }
      },
    ],
  },
  // 财务管理
  {
    path: "/finance",
    component: Layout,
    redirect: "/finance/overview",
    name: "finance",
    meta: {
      title: "finance",
      icon: "finance",
    },
    children: [
      {
        path: "overview",
        component: () => import("@/views/finance/overview"),
        name: "overview",
        meta: { title: "overview" },
      },
      {
        path: "enterprise-overview",
        component: () => import("@/views/finance/enterpriseOverview"),
        name: "enterpriseOverview",
        meta: { title: "enterpriseOverview" },
      },
      {
        path: "shop-overview",
        component: () => import("@/views/finance/shopOverview"),
        name: "shopOverview",
        meta: { title: "shopOverview" },
      },
      {
        path: "summary",
        component: () => import("@/views/finance/summary"),
        name: "summary",
        meta: { title: "summary" },
      },
      {
        path: "finance-summary",
        component: () => import("@/views/finance/summary"),
        name: "financeSummary",
        meta: { title: "financeSummary" },
      },
      {
        path: "detailed",
        component: () => import("@/views/finance/detailed"),
        name: "detailed",
        meta: { title: "detailed" },
      },
      {
        path: "finance-detailed",
        component: () => import("@/views/finance/financeDetailed"),
        name: "financeDetailed",
        meta: { title: "financeDetailed" },
      },
      {
        path: "deal-detailed",
        component: () => import("@/views/finance/dealDetailed"),
        name: "dealDetailed",
        meta: { title: "dealDetailed" },
      },
      // 分销提现审核
      {
        path: "distribution-review",
        component: () => import("@/views/finance/distributionReview"),
        name: "distributionCashWithdrawApprove",
        meta: { title: "distributionCashWithdrawApprove" },
      },
      // 财务账户管理
      {
        path: "/finance/financialAccountManagement",
        component: () => import("@/views/finance/financialAccountManagement/"),
        redirect: "/finance/financialAccountManagement/site-finance",
        name: "financialAccountManagement",
        meta: { title: "financialAccountManagement" },
        children: [
          {// 站点财务
            path: "site-finance",
            component: () => import("@/views/finance/financialAccountManagement/siteFinance"),
            name: "siteFinance",
            meta: { title: "siteFinance" },
          },
          {// 提现账户
            path: "account",
            component: () => import("@/views/finance/financialAccountManagement/account"),
            name: "financeAccount",
            meta: { title: "financeAccount" },
          },
          {// 客户充值审核
            path: "customerRechargeReview",
            component: () => import("@/views/finance/financialAccountManagement/customerRechargeReview"),
            name: "customerRechargeReview",
            meta: { title: "customerRechargeReview" },
          },
          {// 账户人工充值
            path: "manualAccountRecharge",
            component: () => import("@/views/finance/financialAccountManagement/manualAccountRecharge"),
            name: "manualAccountRecharge",
            meta: { title: "manualAccountRecharge" },
          },
          {// 收款账户管理
            path: "collectionAccountManagement",
            component: () => import("@/views/finance/financialAccountManagement/collectionAccountManagement"),
            name: "collectionAccountManagement",
            meta: { title: "collectionAccountManagement" },
          },
          {// 进项明细
            path: "collectionAccountManagement/income/:id",
            name: "income",
            component: () => import("@/views/finance/financialAccountManagement/module/income"),
            meta: {
              noCache: true,
              activeNames: ["collectionAccountManagement"],
            },
            hidden: true,
          },
          {// 项目结算确认
            path: "projectSettlementConfirmation",
            component: () => import("@/views/finance/financialAccountManagement/projectSettlementConfirmation"),
            name: "projectSettlementConfirmation",
            meta: { title: "projectSettlementConfirmation" },
          },
          {
            path: "site-finance/Invoicemangerjiludetail",
            component: () => import("@/views/finance/subSiteFinance/Invoicemangerjiludetail"),
            name: "financeInvoicemangerjiludetail",
            meta: { title: "financeInvoicemangerjiludetail", noCache: true, activeNames: ['siteFinance']},
            hidden: true,
          },
          {
            path: "reconciliationInvoices/detail",
            component: () => import("@/views/finance/subSiteFinance/reconciliationInvoices/detail"),
            name: "reconciliationInvoicesDetail",
            meta: { title: "reconciliationInvoicesDetail", activeNames: ['siteFinance']},
            hidden: true,
          },
          {
            path: ":route/orderDetail/:sn",
            component: () => import("@/views/order/orderList/orderDetail"),
            name: "orderFinanceDetail",
            hidden: true,
            meta: {
              title: "orderFinanceDetail",
              activeNames: ["siteFinance", "dealDetailed", "detailed"],
            },
          },
        ]
      },
      // 核销管理
      {
        path: "/finance/verificationManagement",
        component: () => import("@/views/finance/verificationManagement/"),
        redirect: "/finance/verificationManagement/cancel-after-verification",
        name: "verificationManagement",
        meta: { title: "verificationManagement" },
        children: [
          {// 开卡核销
            path: "cancel-after-verification",
            component: () => import("@/views/finance/verificationManagement/writeOffOpenCard"),
            name: "openCardWrite",
            meta: { title: "openCardWrite" },
          },
          {// 关卡核销
            path: "close-card-write",
            component: () => import("@/views/finance/verificationManagement/writeOffCloseCard"),
            name: "closeCardWrite",
            meta: { title: "closeCardWrite" },
          },
          {// 来往对账详情
            path: "writeOffOpenCardDetail/:id",
            name: "writeOffOpenCardDetail",
            component: () =>
              import("@/views/finance/verificationManagement/writeOffOpenCardDetail"),
            meta: {
              noCache: true,
              activeNames: ["openCardWrite"],
            },
            hidden: true,
          },
          {// 销售订单核销
            path: "verification-order-detail",
            name: "verificationOrderDetail",
            component: () =>
              import("@/views/tools/sale-card-open/modules/saleOrderDetail"),
            meta: {
              activeNames: ["openCardWrite"],
            },
            hidden: true,
          },

          {// 销售退单核销
            path: "verification-refund-detail",
            name: "verificationRefundDetail",
            component: () =>
              import("@/views/tools/sale-card-open/modules/saleRefundDetail"),
            meta: {
              activeNames: ["writeOffOpenCard"],
            },
            hidden: true,
          },
          {// 充值销售单核销详情
            path: "verification-rechargesale-detail",
            name: "verificationRechargeSalesDetail",
            component: () =>
              import("@/views/tools/sale-card-open/modules/rechargeSalesDetail"),
            meta: {
              noCache: true,
              activeNames: ["openCardWrite"],
            },
            hidden: true,
          },
          {// 充值退款单核销详情
            path: "verification-rechargerefund-detail",
            name: "verificationRechargeRefundDetail",
            component: () =>
              import("@/views/tools/sale-card-open/modules/rechargeRefundDetail"),
            meta: {
              noCache: true,
              activeNames: ["openCardWrite"],
            },
            hidden: true,
          },

        ],
      },

      // 查看开/关记录卡详情
      {
        path: "close-all-detail",
        name: "financeCloseAllDetail",
        component: () =>
          import(
            "@/views/tools/sale-card-open/modules/cardRecordDetail/closeAllDetail"
          ),
        meta: {
          noCache: true,
          activeNames: ["closeCardWrite"],
        },
        hidden: true,
      },
      {
        path: "close-part-detail",
        name: "financeClosePartDetail",
        component: () =>
          import(
            "@/views/tools/sale-card-open/modules/cardRecordDetail/closePartDetail"
          ),
        meta: {
          noCache: true,
          activeNames: ["closeCardWrite"],
        },
        hidden: true,
      },
      {
        path: "open-all-detail",
        name: "financeOpenAllDetail",
        component: () =>
          import(
            "@/views/tools/sale-card-open/modules/cardRecordDetail/openAllDetail"
          ),
        meta: {
          noCache: true,
          activeNames: ["closeCardWrite"],
        },
        hidden: true,
      },
      {
        path: "open-part-detail",
        name: "financeOpenPartDetail",
        component: () =>
          import(
            "@/views/tools/sale-card-open/modules/cardRecordDetail/openPartDetail"
          ),
        meta: {
          noCache: true,
          activeNames: ["closeCardWrite"],
        },
        hidden: true,
      },

      {// 销售订单核销
        path: "verification-order-detail",
        name: "verificationOrderDetail",
        component: () =>
          import("@/views/tools/sale-card-open/modules/saleOrderDetail"),
        meta: {
          activeNames: ["openCardWrite"],
        },
        hidden: true,
      },

      {// 销售退单核销
        path: "verification-refund-detail",
        name: "verificationRefundDetail",
        component: () =>
          import("@/views/tools/sale-card-open/modules/saleRefundDetail"),
        meta: {
          activeNames: ["writeOffOpenCard"],
        },
        hidden: true,
      },
      {// 充值销售单核销详情
        path: "verification-rechargesale-detail",
        name: "verificationRechargeSalesDetail",
        component: () =>
          import("@/views/tools/sale-card-open/modules/rechargeSalesDetail"),
        meta: {
          noCache: true,
          activeNames: ["openCardWrite"],
        },
        hidden: true,
      },
      {// 充值退款单核销详情
        path: "verification-rechargerefund-detail",
        name: "verificationRechargeRefundDetail",
        component: () =>
          import("@/views/tools/sale-card-open/modules/rechargeRefundDetail"),
        meta: {
          noCache: true,
          activeNames: ["openCardWrite"],
        },
        hidden: true,
      },
      // {
      //   path: 'cancel-after-verification-detailed',
      //   component: () => import('@/views/finance/writeOffOpenCardDetailed'),
      //   name: 'writeOffOpenCardDetailed',
      //   meta: {
      //     title: 'writeOffOpenCardDetailed',
      //     activeNames: ['writeOffOpenCard'],
      //   },
      //   hidden: true
      // },
      {
        path: "accountg",
        component: () => import("@/views/finance/account"),
        name: "financeAccountg",
        meta: { title: "financeAccountg" },
      },
      {
        path: "accounta",
        component: () => import("@/views/finance/account"),
        name: "financeAccounta",
        meta: { title: "financeAccounta" },
      },
      {
        path: "money",
        component: () => import("@/views/finance/money"),
        name: "guaranteeMoney",
        meta: { title: "guaranteeMoney" },
      },
      {// 短信管理
        path: '/finance/Messages',
        component: () => import('@/views/finance/Messages/'),
        redirect: '/finance/Messages/messageGai',
        name: 'smsManagement',
        meta: { title: 'Messages' },
        children: [
          {// 短信概览
            path: "messageGai",
            component: () => import("@/views/finance/Messages/messageGai"),
            name: "messageGai",
            meta: { title: "messageGai" },
          },
          {// 短信签名管理
            path: "messageManage",
            component: () => import("@/views/finance/Messages/messageManage"),
            name: "messageManage",
            meta: { title: "messageManage" },
            // children: [
            //   {
            //     path: "sqmsg",
            //     component: () => import("@/views/finance/Messages/sqmsg"),
            //     name: "sqmsg",
            //     meta: { title: "sqmsg" },
            //   },
            // ],
          },
          {// 短信签名配置
            path: "messagePei",
            component: () => import("@/views/finance/Messages/messagePei"),
            name: "messagePei",
            meta: { title: "messagePei" },
          }
        ]
      },
      /** 企业端发票管理 */
      {
        path: "managementOfInvoices", // 发票管理
        component: () => import("@/views/finance/managementOfInvoices"),
        name: "managementOfInvoices",
        meta: { title: "managementOfInvoices" },
        children: [
          {
            path: "Invoicemanger", // 企业端发票管理
            component: () => import("@/views/finance/managementOfInvoices/Invoicemanger"),
            name: "Invoicemanger",
            meta: { title: "Invoicemanger" },
          },
          { // 开票确认页
            path: "Invoicemanger/requestInvoice",
            component: () => import("@/views/finance/managementOfInvoices/requestInvoice"),
            name: "requestInvoice",
            hidden: true,
            meta: {
              title: "requestInvoice",
              activeNames: ["Invoicemanger"],
            },
          },
          {
            path: "cardAndInvoiceReview", // 企业端开卡发票审核
            component: () => import("@/views/finance/managementOfInvoices/cardAndInvoiceReview"),
            name: "cardAndInvoiceReview",
            meta: { title: "cardAndInvoiceReview" },
          },
          {
            path: "cardLookInfoReview/:id?", // 企业端开卡发票审核查看
            component: () => import("@/views/finance/managementOfInvoices/cardLookInfoReview"),
            name: "cardLookInfoReview",
            hidden: true,
            meta: {
              title: "cardLookInfoReview",
              noCache: true,
              activeNames: ["cardAndInvoiceReview"],
            },
          },
        ]
      },

      {
        path: "Invoicereview", // 供应商端发票审核
        component: () => import("@/views/finance/Invoicereview2"),
        name: "Invoicereview",
        meta: { title: "Invoicereview" },
      },
      {
        path: "Invoicemanger/administer",
        component: () => import("@/views/finance/administer"),
        name: "administer",
        hidden: true,
        meta: {
          title: "administer",
          activeNames: ["Invoicemanger"],
        },
      },
      {
        path: "Invoicemanger/Invoicemangerdetail",
        component: () => import("@/views/finance/Invoicemangerdetail"),
        name: "Invoicemangerdetail",
        hidden: true,
        meta: {
          title: "Invoicemangerdetail",
          activeNames: ["Invoicemanger"],
        },
      },
      {
        path: "Invoicemanger/Invoicemangerjilu",
        component: () => import("@/views/finance/Invoicemangerjilu"),
        name: "Invoicemangerjilu",
        hidden: true,
        meta: {
          title: "Invoicemangerjilu",
          activeNames: ["Invoicemanger"],
        },
      },
      {
        path: "Invoicemanger/Invoicemangerjiludetail",
        component: () => import("@/views/finance/Invoicemangerjiludetail"),
        name: "Invoicemangerjiludetail",
        hidden: true,
        meta: {
          title: "Invoicemangerjiludetail",
          activeNames: ["Invoicemanger"],
        },
      },
      {
        path: "Invoicemangerg",
        component: () => import("@/views/finance/managementOfInvoices/Invoicemanger"),
        name: "Invoicemangerg",
        meta: { title: "Invoicemangerg" },
      },

      // (企业端)客户账期审批
      {
        path: '/finance/customerAccountApproval',
        component: () => import('@/views/finance/customerAccountApproval/'),
        redirect: '/finance/customerAccountApproval/departmentalApprovalList',
        name: 'customerAccountApproval',
        meta: { title: 'customerAccountApproval' },
        children: [
          {// 部门审批列表
            path: "departmentalApprovalList",
            component: () => import("@/views/finance/customerAccountApproval/departmentalApprovalList"),
            name: "departmentalApprovalList",
            meta: { title: "departmentalApprovalList" },
          },
          {// 财务复审列表
            path: "financialReviewList",
            component: () => import("@/views/finance/customerAccountApproval/financialReviewList"),
            name: "financialReviewList",
            meta: { title: "financialReviewList" },
          },
        ]
      },
      // (企业端)定制折扣审批
      {
        path: '/finance/customizedDiscountApproval',
        component: () => import('@/views/finance/customizedDiscountApproval/'),
        redirect: '/finance/customizedDiscountApproval/departmentalApprovalListDZ',
        name: 'customizedDiscountApproval',
        meta: { title: 'Messages' },
        children: [
          {// 部门审批列表
            path: "departmentalApprovalListDZ",
            component: () => import("@/views/finance/customizedDiscountApproval/departmentalApprovalListDZ"),
            name: "departmentalApprovalListDZ",
            meta: { title: "departmentalApprovalListDZ" },
          },
          {// 财务复审列表
            path: "financialReviewListDZ",
            component: () => import("@/views/finance/customizedDiscountApproval/financialReviewListDZ"),
            name: "financialReviewListDZ",
            meta: { title: "financialReviewListDZ" },
          },
        ]
      },
      {
        path: ":route/detail/:sn",
        component: () => import("@/views/finance/detail"),
        name: "detail",
        hidden: true,
        meta: {
          title: "detail",
          activeNames: ["financeDetailed"],
        },
      },
      {
        path: ":route/orderDetail/:sn",
        component: () => import("@/views/order/orderList/orderDetail"),
        name: "orderFinanceDetail",
        hidden: true,
        meta: {
          title: "orderFinanceDetail",
          activeNames: ["siteFinance", "dealDetailed", "detailed"],
        },
      },
      {
        path: ":route/union-order-detail/:sn",
        component: () => import("@/views/order/unionOrderDetail"),
        name: "orderFinanceUndetail",
        hidden: true,
        meta: {
          title: "orderFinanceUndetail",
          activeNames: ["siteFinance", "dealDetailed", "detailed"],
        },
      },
      {
        path: "lookInvoicereview/:id?",
        component: () => import("@/views/finance/lookInvoicereview"),
        name: "lookInvoicereview",
        hidden: true,
        meta: {
          title: "lookInvoicereview",
          activeNames: ["Invoicereview"],
        },
      },
    ],
  },
  // 统计
  {
    path: "/statistics",
    component: Layout,
    redirect: "/statistics/generality-overview",
    name: "statistics",
    meta: {
      title: "statistics",
      icon: "data",
    },
    children: [
      {
        path: "shop-flow",
        component: () => import("@/views/statistics/analysis/shopFlow"),
        name: "shopFlow",
        meta: { title: "shopFlow" },
      },
      {
        path: "shop-user",
        component: () => import("@/views/statistics/analysis/shopUser"),
        name: "shopUser",
        meta: { title: "shopUser" },
      },
      {
        path: "shop-active",
        component: () => import("@/views/statistics/analysis/shopActive"),
        name: "shopActive",
        meta: { title: "shopActive" },
      },
      {
        path: "shop-data-today",
        component: () => import("@/views/statistics/analysis/shopDataToday"),
        name: "shopDataToday",
        meta: { title: "shopDataToday" },
      },
      {
        path: "shop-data-daily",
        component: () => import("@/views/statistics/analysis/shopDataDaily"),
        name: "shopDataDaily",
        meta: { title: "shopDataDaily" },
      },
      {
        path: "data-today",
        component: () => import("@/views/statistics/analysis/dataToday"),
        name: "dataToday",
        meta: { title: "dataToday" },
      },
      {
        path: "deal-revenue",
        component: () => import("@/views/statistics/analysis/dealRevenue"),
        name: "dealRevenue",
        meta: { title: "dealRevenue" },
      },
      {
        path: "revenue",
        component: () => import("@/views/statistics/analysis/revenue"),
        name: "revenue",
        meta: { title: "revenue" },
      },
      {
        path: "order",
        component: () => import("@/views/statistics/analysis/order"),
        name: "orderStatistics",
        meta: { title: "orderStatistics" },
      },
      {
        path: "goods",
        component: () => import("@/views/statistics/analysis/goods"),
        name: "goodsStatistics",
        meta: { title: "goodsStatistics" },
      },
    ],
  },
  // 商城管理
  {
    path: "/application",
    component: Layout,
    redirect: "/application/shop-management",
    name: "application",
    meta: {
      title: "application",
      icon: "app",
    },
    children: [
      {
        path: "shop-management",
        component: () =>
          import("@/pages/application/shop-management/enterprise/shop-list"),
        name: "shopManagement",
        hidden: true,
        force: true,
        meta: { title: "shopManagement" },
      },
      // 新增现金商城
      {
        path: "mallManagementEdit",
        component: () => import("@/views/application/mallManagementEdit"),
        name: "mallManagementEdit",
        hidden: true,
        meta: { title: "mallManagementEdit" },
      },
      // 新增套餐卡商城
      {
        path: "combo-card-shop-edit",
        component: () => import("@/views/application/combo-card-shop-edit.vue"),
        name: "comboCardShopEdit",
        hidden: true,
        meta: { title: "comboCardShopEdit" },
      },
      // 新增双选商城菜单
      {
        path: 'doubleSelectionEdit',
        component: () => import('@/views/application/doubleSelectionEdit.vue'),
        name: 'doubleSelectionEdit',
        hidden: true,
        meta: { title: 'doubleSelectionEdit' }
      },
      // 新增一件代发商城菜单
      {
        path: 'oneshoppingnEdit',
        component: () => import('@/views/application/oneshoppingnEdit.vue'),
        name: 'oneshoppingnEdit',
        hidden: true,
        meta: { title: 'oneshoppingnEdit' }
      },
      // 充值卡
      {
        path: "czkadd",
        component: () => import("@/views/application/czkMeal/czkMealAdd"),
        name: "czkMealAdd",
        hidden: true,
        meta: { title: "czkMealAdd" },
      },
      {
        path: "czklist",
        component: () => import("@/views/application/czklist"),
        name: "czkMealList",
        hidden: true,
        meta: { title: "czkMealList" },
      },
      {
        path: "exchangeCode1",
        component: () => import("@/views/application/dhm"),
        name: "exchangeCodelist1",
        hidden: true,
        meta: { title: "exchangeCodelist1" },
      },
      // {
      //   path: 'set-meal',
      //   component: () => import('@/views/application/setMeal/index'),
      //   redirect: '/application/set-meal/add',
      //   name: 'setMeal',
      //   meta: { title: 'setMeal' },
      //   children: [
      //     { path: 'add', component: () => import('@/views/application/setMeal/setMealAdd'), name: 'setMealAdd', meta: { title: 'setMealAdd' }},
      //     {
      //       path: 'grant',
      //       component: () => import('@/views/application/grant'),
      //       name: 'grant',
      //       meta: { title: 'grant' },
      //     },
      //     { path: 'grant-details/:id?', component: () => import('@/views/application/grantDetails'), name: 'grantDetails', meta: { title: 'grantDetails' }, hidden: true},
      //     {
      //       path: 'my-grant',
      //       component: () => import('@/views/application/myGrant'),
      //       name: 'myGrant',
      //       meta: { title: 'myGrant' },
      //     },
      //     {
      //       path: 'grant-use',
      //       component: () => import('@/views/application/grantUse'),
      //       name: 'grantUse',
      //       meta: { title: 'grantUse' },
      //     },
      //     {
      //       path: 'grant-detail/:id?',
      //       component: () => import('@/views/application/grantDetail'),
      //       name: 'grantDetail',
      //       meta: { title: 'grantDetail' },
      //       hidden: true
      //     },
      //   ]
      // },
      {
        path: "add",
        component: () => import("@/views/application/setMeal/setMealAdd"),
        name: "setMealAdd",
        meta: { title: "setMealAdd" },
        hidden: true,
      },
      {
        path: "grant",
        component: () => import("@/views/application/grant"),
        name: "grant",
        meta: { title: "grant" },
        hidden: true,
      },
      {
        path: "my-grant",
        component: () => import("@/views/application/myGrant"),
        name: "myGrant",
        meta: { title: "myGrant" },
        hidden: true,
      },
      {
        path: "grant-use",
        component: () => import("@/views/application/grantUse"),
        name: "grantUse",
        meta: { title: "grantUse" },
        hidden: true,
      },
      {
        path: "grant-detail/:id?",
        component: () => import("@/views/application/grantDetail"),
        name: "grantDetail",
        meta: { title: "grantDetail" },
        hidden: true,
      },
      {
        path: "grant-details/:id?",
        component: () => import("@/views/application/grantDetails"),
        name: "grantDetails",
        meta: { title: "grantDetails" },
        hidden: true,
      },

      // 方案报价列表
      // {
      //   path: 'fabjlist',
      //   component: () => import("@/views/application/fabjlist"),
      //   name: 'fabjlist',
      //   hidden: true,
      //   meta: { title: 'fabjlist' }
      // },
    ],
  },
  // 设置
  {
    path: "/setting",
    component: Layout,
    redirect: "/setting/person",
    name: "setting",
    meta: {
      title: "setting",
      icon: "settings",
    },
    children: [
      {
        path: "safeSetting",
        component: () => import("@/views/setting/safeSetting"),
        name: "safeSetting",
        meta: { title: "safeSetting" },
      },
      {
        path: "shop-transportg",
        component: () => import("@/views/setting/transportg"),
        name: "transportg",
        meta: { title: "transportg" },
      },
      // 分销设置
      {
        path: 'distributions-settings',
        component: () => import('@/views/setting/distributionSettings'),
        name: 'goodsDistributionRateSettings',
        meta: { title: 'goodsDistributionRateSettings'}
      },
      {
        path: "shop-mesg",
        component: () => import("@/views/setting/shopMesg"),
        name: "shopMesg",
        meta: { title: "shopMesg" },
      },
      {
        path: "shop-agree",
        component: () => import("@/views/setting/agreeIn"),
        name: "agreeIn",
        meta: { title: "agreeIn" },
      },
      {
        path: "shareSetting",
        component: () => import("@/views/setting/shareSetting"),
        name: "shareSetting",
        meta: { title: "shareSetting" },
      },
      {
        path: "shop-person",
        component: () => import("@/views/setting/person"),
        name: "shopPerson",
        meta: { title: "shopPerson" },
      },
      {// 企业认证
        path: "shop-identify",
        component: () => import("@/views/setting/identify"),
        name: "shopIdentify",
        meta: { title: "shopIdentify" },
      },
      {// 站点认证
        path: "mall-identify",
        component: () => import("@/views/setting/mallIdentify"),
        name: "mallIdentify",
        meta: { title: "mallIdentify" },
      },
      {
        path: "shop-address",
        component: () => import("@/views/setting/address"),
        name: "shopAddress",
        meta: { title: "shopAddress" },
      },
      {
        path: "shop-addressa",
        component: () => import("@/views/setting/address"),
        name: "shopAddressa",
        meta: { title: "shopAddressa" },
      },
      {
        path: "shop-payment",
        component: () => import("@/views/setting/payment"),
        name: "shopPayment",
        meta: { title: "shopPayment" },
      },
      {
        path: "shop-setting",
        component: () => import("@/views/setting/shopSettings"),
        name: "shopSettings",
        meta: { title: "shopSettings" },
      },
      {
        path: "goods-warning",
        component: () => import("@/views/setting/goodsWarning"),
        name: "goodsWarning",
        meta: { title: "goodsWarning" },
      },

      {
        path: "shop-companyList",
        component: () => import("@/views/setting/companyList"),
        name: "shopCompanyList",
        meta: { title: "shopCompanyList" },
      },
      {
        path: "shop-companyLista",
        component: () => import("@/views/setting/companyList"),
        name: "shopCompanyLista",
        meta: { title: "shopCompanyLista" },
      },
      {
        path: "shop-giftLableList", // 礼包标签管理
        component: () => import("@/views/setting/giftLableList"),
        name: "giftLableList",
        meta: { title: "giftLableList" },
      },
      {
        path: "customerProfile", // 客户档案（企业端）
        component: () => import("@/views/setting/customerProfile/customerProfile"),
        name: "customerProfile",
        meta: { title: "customerProfile" },
      },
      {
        path: "customerProfile/editCustomerProfile", // 客户档案-编辑（企业端）
        component: () => import("@/views/setting/customerProfile/editCustomerProfile"),
        name: "editCustomerProfile",
        hidden: true,
        meta: {
          title: "editCustomerProfile",
          noCache: true,
          activeNames: ["customerProfile"],
        },
      },
      {
        path: "cardOpeningPersonnelManagement", // 开卡人员管理（企业端）
        component: () => import("@/views/setting/cardUserManage/list"),
        name: "cardOpeningPersonnelManagement",
        meta: { title: "cardOpeningPersonnelManagement" },
      },
      {
        path: `editCardUserManage/:id?`, // 开卡人员管理-编辑（企业端）
        component: () => import("@/views/setting/cardUserManage/edit"),
        name: "editCardUserManage",
        hidden: true,
        meta: {
          title: "editCardUserManage",
          noCache: false,
          activeNames: ["cardOpeningPersonnelManagement"],
        },
      },
      {
        path: "sellshop-agree", // 开卡用户协议（企业端）
        component: () => import("@/views/setting/agreeIn"),
        name: "cardUserAgreement",
        meta: { title: "cardUserAgreement" },
      },

      {
        path: "customizedDiscount", // 定制折扣模板管理（企业端）
        component: () => import("@/views/setting/customizedDiscount/list"),
        name: "customizedDiscountTemplateManagement",
        meta: { title: "customizedDiscountTemplateManagement" },
      },
      {
        path: "revokeCardKey",
        component: () => import("@/views/setting/revokeCardKey"),
        name: "revoke_card_key",
        meta: { title: "revokeCardKey" },
      },
      // { 把供应商运费隐藏了  供应商不再提供运费；见禅道bug 5531
      //   path: 'shop-transportationExp',
      //   component: () => import('@/views/setting/transportationExp'),
      //   name: 'shopTransportationExp',
      //   meta: {title: 'shopTransportationExp'}
      // },
      // 站点运费这里先不上线  还原到老的版本 见禅道bug 5529
      // 禅道 5836 暂时隐藏免责声明
      // {
      //   path: 'shop-agreea',
      //   component: () => import('@/views/setting/agreeIna'),
      //   name: 'agreeIna',
      //   meta: {title: 'agreeIna'}
      // }
      {
        path: "grade-application",
        component: () => import("@/views/setting/gradeApplication"),
        name: "gradeApplication",
        meta: { title: "gradeApplication" },
        hidden: true,
      },
    ],
  },

  // 工具模块
  {
    path: "/tools",
    component: Layout,
    redirect: "/tools/promotion-manage",
    name: "tools",
    meta: {
      title: "tools",
      icon: "tools",
    },
    children: [
      {
        path: "cash-coupon-list",
        component: () =>
          import("@/views/tools/promotion-manage/cash-coupon-list"),
        name: "cashCouponList",
        meta: {
          title: "cashCouponList",
        }
      },
      { // 促销管理
        path: "/tools/promotion-manage",
        component: () => import("@/views/tools/promotion-manage/index.vue"),
        redirect: "/tools/promotion-manage/coupon-list",
        name: "promotionManage",
        meta: {
          title: "promotionManage",
        },
        children: [
          /**
           * 商家端
           * coupon 优惠券
           * cashCoupon 储值卡 原需求中的代金券
           * 企业端
           * ------另外企业端有个充值卡
           */
          {
            path: "coupon-list",
            component: () =>
              import("@/views/tools/promotion-manage/coupon-list"),
            name: "couponList",
            meta: {
              title: "couponList",
            },
          },
          /**
           * 拼团活动列表
           */
          {
            path: 'group-buying-ctivity',
            component: () => import('@/views/tools/promotion-manage/group-buying-ctivity'),
            name: 'activityGroupBuy',
            meta: {
              title: 'groupBuyingCtivity'
            }
          },
          /**
           * 拼团活动列表-管理
           */
          {
            path: 'group-buying-goods',
            component: () => import('@/views/tools/promotion-manage/group-buying-goods'),
            name: 'groupBuyingGoods',
            hidden: true,
            meta: {
              title: 'groupBuyingGoods',
              activeNames: ['groupBuyingCtivity']
            }
          },
          {
            path: "coupon-edit",
            component: () => import("@/views/tools/promotion-manage/card-edit"),
            name: "couponEdit",
            hidden: true,
            meta: {
              title: "couponEdit",
              activeNames: ["couponList"],
            },
          },
          {
            path: "cash-coupon-edit",
            component: () => import("@/views/tools/promotion-manage/card-edit"),
            name: "cashCouponEdit",
            hidden: true,
            meta: {
              title: "cashCouponEdit",
              activeNames: ["cashCouponList"],
            },
          },
          {
            path: "exchangeCode",
            hidden: true,
            component: () => import("@/views/application/dhm"),
            name: "exchangeCodelist",
            meta: {
              title: "exchangeCodelist",
              activeNames: ["couponList"],
            },
          },
        ],
      },
      { // 销售人员管理
        path: "/tools/sale-manage",
        component: () => import("@/views/tools/sale-manage/"),
        redirect: "/tools/sale-manage/sales-list",
        name: "saleManage",
        meta: {
          title: "saleManage",
        },
        children: [
          {
            path: "sales-list",
            component: () => import("@/views/tools/sale-manage/sales-list"),
            name: "salesList",
            meta: {
              title: "salesList",
            },
          },
          {
            path: "sales-edit",
            component: () => import("@/views/tools/sale-manage/sales-edit"),
            name: "salesEdit",
            hidden: true,
            meta: {
              title: "salesEdit",
            },
          },
          {
            path: "programmeList",
            component: () => import("@/views/shopGoods/programmeList"),
            name: "programmeList",
            meta: { title: "programmeList" },
          },
          {
            path: "programmeRecord",
            component: () => import("@/views/shopGoods/programmeRecord"),
            name: "programmeRecord",
            meta: { title: "programmeRecord" },
          },
          {
            path: "programmeDetail/:id",
            component: () => import("@/views/shopGoods/programmeDetail"),
            name: "programmeDetail",
            hidden: true,
            meta: { title: "programmeDetail" },
          },
        ],
      },
      // 销售开卡
      {
        path: "sale-card-open-management",
        name: "saleCardOpenManagement",
        component: () => import("@/views/tools/sale-card-open/index"),
        meta: { title: `saleCardOpenManagement` },
      },
      // 销售关卡
      {
        path: "sale-card-close-management",
        name: "saleCardCloseManagement",
        component: () => import("@/views/tools/sale-card-open/closeIndex"),
        meta: { title: `saleCardCloseManagement` },
      },
      // 卡号查询
      {
        path: "card-no-search",
        name: "cardSearch",
        component: () => import("@/views/tools/sale-card-open/cardNoSearch"),
      },
      // 发票信息补录
      {
        path: "card-no-additional-recording",
        name: "supplementaryRecordingOfInvoiceInformation",
        component: () => import("@/views/tools/sale-card-open/supplementaryRecordingOfInvoiceInformation"),
      },
      {
        path: "InformationSupplementation/:id?", // 企业端发票信息补录处理
        component: () => import("@/views/tools/sale-card-open/modules/InformationSupplementation"),
        name: "InformationSupplementation",
        hidden: true,
        meta: {
          title: "InformationSupplementation",
          noCache: true,
          activeNames: ["supplementaryRecordingOfInvoiceInformation"],
        },
      },
      {
        path: "cardLookReview/:id?", // 企业端发票信息补录查看详情
        component: () => import("@/views/tools/sale-card-open/modules/cardLookReview"),
        name: "toolsCardLookReview",
        hidden: true,
        meta: {
          title: "cardLookReview",
          noCache: true,
          activeNames: ["supplementaryRecordingOfInvoiceInformation"],
        },
      },
      {
        path: "edit-sale-close-record",
        name: "editSaleCloseRecord",
        component: () =>
          import("@/views/tools/sale-card-open/modules/editSaleCloseRecord"),
        meta: {
          activeNames: ["saleCardCloseManagement"],
        },
        hidden: true,
      },
      {
        path: "edit-sale-open-record",
        name: "editSaleOpenRecord",
        component: () =>
          import("@/views/tools/sale-card-open/modules/editSaleOpenRecord"),
        meta: {
          activeNames: ["saleCardCloseManagement"],
        },
        hidden: true,
      },
      // 查看开/关记录卡详情
      {
        path: "close-all-detail",
        name: "closeAllDetail",
        component: () =>
          import(
            "@/views/tools/sale-card-open/modules/cardRecordDetail/closeAllDetail"
          ),
        meta: {
          noCache: true,
          activeNames: ["saleCardCloseManagement"],
        },
        hidden: true,
      },
      {
        path: "close-part-detail",
        name: "closePartDetail",
        component: () =>
          import(
            "@/views/tools/sale-card-open/modules/cardRecordDetail/closePartDetail"
          ),
        meta: {
          noCache: true,
          activeNames: ["saleCardCloseManagement"],
        },
        hidden: true,
      },
      {
        path: "open-all-detail",
        name: "openAllDetail",
        component: () =>
          import(
            "@/views/tools/sale-card-open/modules/cardRecordDetail/openAllDetail"
          ),
        meta: {
          noCache: true,
          activeNames: ["saleCardCloseManagement"],
        },
        hidden: true,
      },
      {
        path: "open-part-detail",
        name: "openPartDetail",
        component: () =>
          import(
            "@/views/tools/sale-card-open/modules/cardRecordDetail/openPartDetail"
          ),
        meta: {
          noCache: true,
          activeNames: ["saleCardCloseManagement"],
        },
        hidden: true,
      },
      // 销售订单记录
      {
        path: "add-sale-order-record",
        name: "addSaleOrderRecord",
        component: () =>
          import("@/views/tools/sale-card-open/modules/addSaleOrderRecord"),
        meta: {
          title: `addSaleOrderRecord`,
          noCache: true,
          activeNames: ["saleCardOpenManagement"],
        },
        hidden: true,
      },
      {
        path: "sale-order-detail",
        name: "saleOrderDetail",
        component: () =>
          import("@/views/tools/sale-card-open/modules/saleOrderDetail"),
        meta: {
          activeNames: ["saleCardOpenManagement"],
        },
        hidden: true,
      },
      {
        path: "sale-refund-detail",
        name: "saleRefundDetail",
        component: () =>
          import("@/views/tools/sale-card-open/modules/saleRefundDetail"),
        meta: {
          // activeNames: ["saleCardOpenManagement"],
        },
        hidden: true,
      },
      {
        path: "recharge-sale-detail",
        name: "rechargeSalesDetail",
        component: () =>
          import("@/views/tools/sale-card-open/modules/rechargeSalesDetail"),
        meta: {
          activeNames: ["saleCardOpenManagement"],
        },
        hidden: true,
      },
      {
        path: "recharge-refund-detail",
        name: "rechargeRefundDetail",
        component: () =>
          import("@/views/tools/sale-card-open/modules/rechargeRefundDetail"),
        meta: {
          activeNames: ["saleCardOpenManagement"],
        },
        hidden: true,
      },
      {
        path: "add-sale-refund-record",
        name: "addSaleRefundRecord",
        component: () =>
          import("@/views/tools/sale-card-open/modules/addSaleRefundRecord"),
        meta: {
          title: `addSaleRefundRecord`,
          noCache: true,
          activeNames: ["saleCardOpenManagement"],
        },
        hidden: true,
      },
      // 销售开卡-添加充值销售单记录
      {
        path: "add-recharge-sales",
        name: "addRechargeSales",
        component: () =>
          import("@/views/tools/sale-card-open/modules/addRechargeSales"),
        meta: {
          title: `addRechargeSales`,
          noCache: true,
          activeNames: ["saleCardOpenManagement"],
        },
        hidden: true,
      },
      // 销售开卡-添加充值退款单记录
      {
        path: "add-recharge-refund",
        name: "addRechargeRefund",
        component: () =>
          import("@/views/tools/sale-card-open/modules/addRechargeRefund"),
        meta: {
          title: `addRechargeRefund`,
          noCache: true,
          activeNames: ["saleCardOpenManagement"],
        },
        hidden: true,
      },
      // 分享文案管理
      {
        path: `share-video-list`,
        component: () => import(`@/views/tools/share-video/share-video-list`),
        name: `shareVideoList`,
        meta: { title: `shareVideoList` },
      },
      {
        path: `share-video-edit/:id?`,
        component: () => import(`@/views/tools/share-video/share-video-edit`),
        name: `shareVideoEdit`,
        meta: {
          title: `shareVideoEdit`,
          noCache: true,
          activeNames: ["shareVideoList"],
        },
        hidden: true,
      },
    ],
  },

  // 店员管理
  {
    path: "/shop-auth",
    component: Layout,
    redirect: "/shop-auth/shop-assistant",
    name: "shopAuth",
    meta: {
      title: "shopAuth",
      icon: "clerk",
    },
    children: [
      {
        path: "shop-assistant",
        component: () => import("@/views/shop-auth/shopAssistant"),
        name: "shopAssistant",
        meta: { title: "shopAssistant" },
      },
      {
        path: "role-manage",
        component: () => import("@/views/shop-auth/roleManage"),
        name: "roleManage",
        meta: { title: "roleManage" },
      },
      {
        path: "role-permission/:id(\\d+)",
        component: () => import("@/views/shop-auth/rolePermission"),
        name: "rolePermission",
        hidden: true,
        meta: {
          title: "rolePermission",
          noCache: true,
          activeNames: ["roleManage"],
        },
      },
    ],
  },

  // 权限
  {
    path: "/shop-auth",
    component: Layout,
    redirect: "/shop-auth/shop-assistantq",
    name: "shopAuthq",
    meta: {
      title: "shopAuthq",
      icon: "jurisdiction",
    },
    children: [
      {
        path: "shop-assistantq",
        component: () => import("@/views/shop-auth/shopAssistantq"),
        name: "shopAssistantq",
        meta: { title: "shopAssistantq" },
      },
      {
        path: "role-manage",
        component: () => import("@/views/shop-auth/roleManage"),
        name: "roleManage",
        meta: { title: "roleManage" },
      },
      {
        path: "card-sale-manage",
        component: () => import("@/views/shop-auth/salesmanManage"),
        name: "cardSaleManage",
        meta: { title: "cardSaleManage" },
      },
      {
        path: "role-permission/:id(\\d+)",
        component: () => import("@/views/shop-auth/rolePermission"),
        name: "rolePermission",
        hidden: true,
        meta: {
          title: "rolePermission",
          noCache: true,
          activeNames: ["roleManage"],
        },
      },
    ],
  },
  // 供应商管理
  {
    path: '/supplier',
    component: Layout,
    redirect: '/supplier/supplier-list',
    name: 'supplier',
    meta: {
      title: 'supplier',
      icon: 'supplier'
    },
    children: [{
      path: 'supplier-list',
      component: () => import('@/views/supplier/supplierList'),
      name: 'supplierList',
      meta: {
        title: 'supplierList'
      }
    },

    {
      path: '/supplier/rechargeriew',
      component: () => import('@/views/supplier/rechargeriew/'),
      redirect: '/supplier/rechargeriew/rechagriew',
      name: 'rechargeriew',
      meta: {title: 'rechargeriew'},
      children: [
        {
          path: 'rechagriew',
          component: () => import('@/views/supplier/rechargeriew/rechagriew'),
          name: 'rechagriew',
          meta: {
            title: 'rechagriew'
          }
        },
        {
          path: 'paymentaccount',
          component: () => import('@/views/supplier/rechargeriew/paymentaccount'),
          name: 'paymentaccount',
          meta: {
            title: 'paymentaccount'
          }
        },
        {
          path: 'chagriew',
          component: () => import('@/views/supplier/rechargeriew/chagriew'),
          name: 'chagriew',
          meta: {
            title: 'chagriew'
          }
        },
        {
          path: 'Invoicemangerdetail',
          component: () => import('@/views/finance/invoice/Invoicemangerdetail'),
          name: 'Invoicemangerdetail',
          hidden: true,
          meta: {
            title: 'Invoicemangerdetail',
            activeNames: ['terraceInvoice']
          }
        },
        {
          path: 'Invoicemangerjilu',
          component: () => import('@/views/finance/invoice/Invoicemangerjilu'),
          name: 'Invoicemangerjilu',
          hidden: true,
          meta: {
            title: 'Invoicemangerjilu',
            activeNames: ['terraceInvoice']
          }
        },
      ]
    },
    {
      path: 'terraceInvoice',
      component: () => import('@/views/supplier/terraceInvoice'),
      name: 'terraceInvoice',
      meta: {
        title: 'terraceInvoice'
      }
    },
    {
      path: 'Invoicemangerdetail',
      component: () => import('@/views/finance/Invoicemangerdetail'),
      name: 'terraceInvoice',
      hidden: true,
      meta: {
        title: 'terraceInvoice'
      }
    },
    {
      path: 'auditHistoryDetailSeller',
      component: () => import('@/views/finance/invoice/auditHistoryDetail'),
      name: 'auditHistoryDetailSeller',
      hidden: true,
      meta: {
        title: 'auditHistoryDetailSeller',
        activeNames: ['terraceInvoice']
      }
    },
    {
      path: 'requestInvoice',
      component: () => import('@/views/supplier/rechargeriew/requestInvoice'),
      name: 'requestInvoice',
      hidden: true,
      meta: {title: 'requestInvoice', activeNames: ['terraceInvoice']},
    },
    {
      path: 'Invoicemangerjilu',
      component: () => import('@/views/finance/invoice/Invoicemangerjilu'),
      name: 'Invoicemangerjilu',
      hidden: true,
      meta: {
        title: 'Invoicemangerjilu',
        activeNames: ['terraceInvoice']
      }
    },
    {
      path: 'supplier-edit/:id',
      component: () => import('@/views/supplier/supplierEdit'),
      name: 'supplierEdit',
      hidden: true,
      meta: {
        title: 'supplierEdit'
      }
    },
    {
      path: 'retentionChange/:id',
      component: () => import('@/views/supplier/retentionChange'),
      name: 'retentionChange',
      meta: {
        title: 'retentionChange',
        activeNames: ['supplierList']
      },
      hidden: true
    },
    ]
  },
  // 通知
  {
    path: "/info",
    component: Layout,
    redirect: "/info/infoList",
    name: "info",
    meta: {
      title: "info",
      icon: "notify",
    },
    children: [
      {
        path: "infoList",
        component: () => import("@/views/info/infoList"),
        name: "infoList",
        meta: { title: "infoList" },
      },
      {// 公告发布
        path: "noticeIdentify",
        component: () => import("@/views/info/noticeIdentify"),
        name: "noticeIdentify",
        meta: { title: "noticeIdentify" },
      },
    ],
  },
  ...comboCardShopRouterMap,
  {
    path: "*",
    hidden: true,
    redirect: "/404",
  },
];

export const comboCardShopRouterMapTemp = [...comboCardShopRouterMap];

export default new Router({
  mode: "history",
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap,
});
