import {Foundation} from '@/../ui-utils';
import {parseTime as $parseTime, formatTime as $formatTime} from '@/utils';

/**
 * 处理高精度减法
 * @param {*} time 
 * @param {*} label 
 * @returns 
 */
export function subtraction (arg1, arg2) {
  if (arg1 == undefined) {
    arg1 = 0;
  } 
  if (arg2 == undefined) {
    arg2 = 0;
  } 
  //写一个高精度的浮点数减法
  var r1, r2, m, n;
    try {
        r1 = arg1.toString().split(".")[1].length;
    }
    catch (e) {
        r1 = 0;
    }
    try {
        r2 = arg2.toString().split(".")[1].length;
    }
    catch (e) {
        r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2)); //last modify by deeka //动态控制精度长度
    n = (r1 >= r2) ? r1 : r2;
    return parseFloat(((arg1 * m - arg2 * m) / m).toFixed(n));
}
/**
 * 处理高精度加法
 * @param {*} time 
 * @param {*} label 
 * @returns 
 */
export function addition(arg1, arg2) {
  //写一个高精度的浮点数加法
  var r1, r2, m, c;
	    try {
	        r1 = arg1.toString().split(".")[1].length;
	    }
	    catch (e) {
	        r1 = 0;
	    }
	    try {
	        r2 = arg2.toString().split(".")[1].length;
	    }
	    catch (e) {
	        r2 = 0;
	    }
	    c = Math.abs(r1 - r2);
	    m = Math.pow(10, Math.max(r1, r2));
	    if (c > 0) {
	        var cm = Math.pow(10, c);
	        if (r1 > r2) {
	            arg1 = Number(arg1.toString().replace(".", ""));
	            arg2 = Number(arg2.toString().replace(".", "")) * cm;
	        } else {
	            arg1 = Number(arg1.toString().replace(".", "")) * cm;
	            arg2 = Number(arg2.toString().replace(".", ""));
	        }
	    } else {
	        arg1 = Number(arg1.toString().replace(".", ""));
	        arg2 = Number(arg2.toString().replace(".", ""));
	    }
	    return (arg1 + arg2) / m;
}
/**
 * 处理高精度乘法
 * @param {*} time 
 * @param {*} label 
 * @returns 
 */
export function rideitionNumber(arg1, arg2) {
  var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
  try {
      m += s1.split(".")[1].length;
  }
  catch (e) {
  }
  try {
      m += s2.split(".")[1].length;
  }
  catch (e) {
  }
  return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
}
/**
 * 处理高精度除法
 * @param {*} time 
 * @param {*} label 
 * @returns 
 */
export function divNum(arg1, arg2) {
  var t1 = 0, t2 = 0, r1, r2;
  try {
      t1 = arg1.toString().split(".")[1].length;
  }
  catch (e) {
  }
  try {
      t2 = arg2.toString().split(".")[1].length;
  }
  catch (e) {
  }
  r1 = Number(arg1.toString().replace(".", ""));
  r2 = Number(arg2.toString().replace(".", ""));
  return (r1 / r2) * Math.pow(10, t2 - t1);
}


function pluralize(time, label) {
  if (time === 1) {
    return time + label
  }
  return time + label + 's'
}

export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time)
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute')
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour')
  } else {
    return pluralize(~~(between / 86400), ' day')
  }
}

export const parseTime = $parseTime;
export const formatTime = $formatTime;

/* 数字 格式化 */
export function nFormatter(num, digits) {
  const si = [
    {
      value: 1E18,
      symbol: 'E'
    },
    {
      value: 1E15,
      symbol: 'P'
    },
    {
      value: 1E12,
      symbol: 'T'
    },
    {
      value: 1E9,
      symbol: 'G'
    },
    {
      value: 1E6,
      symbol: 'M'
    },
    {
      value: 1E3,
      symbol: 'k'
    }
  ]
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value + 0.1).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
    }
  }
  return num.toString()
}

export function html2Text(val) {
  const div = document.createElement('div')
  div.innerHTML = val
  return div.textContent || div.innerText
}

export function toThousandslsFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}

/**
 * 金钱单位置换  2999 --> 2,999.00
 * @param val
 * @param unit
 * @returns {*}
 */
export function unitPrice(val, unit) {
  return (unit || '') + Foundation.formatPrice(val)
}

/**
 * 百分比置换  2999 --> 2999.00 %
 * @param val
 * @param unit
 * @returns {*}
 */
export function unitCompare(val, unit) {
  return val + (unit || '%')
}

/**
 * 处理unix时间戳，转换为可阅读时间格式
 * @param unix
 * @param format
 * @returns {*|string}
 */
export function unixToDate(unix, format) {
  let _format = format || 'yyyy-MM-dd hh:mm:ss'
  if (!unix) {
    return ''
  }
  const d = new Date(unix * 1000)
  const o = {
    'M+': d.getMonth() + 1,
    'd+': d.getDate(),
    'h+': d.getHours(),
    'm+': d.getMinutes(),
    's+': d.getSeconds(),
    'q+': Math.floor((d.getMonth() + 3) / 3),
    S: d.getMilliseconds()
  }
  if (/(y+)/.test(_format)) _format = _format.replace(RegExp.$1, (d.getFullYear() + '').substr(4 - RegExp.$1.length))
  for (const k in o) if (new RegExp('(' + k + ')').test(_format)) _format = _format.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
  return _format
}

/**
 * 处理unix时间戳，转换为可阅读时间格式(毫秒级)
 * @param unix
 * @param format
 * @returns {*|string}
 */
export function secondUnixToDate(unix, format) {
  let _format = format || 'yyyy-MM-dd hh:mm:ss'
  if (!unix) {
    return ''
  }
  const d = new Date(unix)
  const o = {
    'M+': d.getMonth() + 1,
    'd+': d.getDate(),
    'h+': d.getHours(),
    'm+': d.getMinutes(),
    's+': d.getSeconds(),
    'q+': Math.floor((d.getMonth() + 3) / 3),
    S: d.getMilliseconds()
  }
  if (/(y+)/.test(_format)) _format = _format.replace(RegExp.$1, (d.getFullYear() + '').substr(4 - RegExp.$1.length))
  for (const k in o) if (new RegExp('(' + k + ')').test(_format)) _format = _format.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
  return _format
}

/**
 * 根据订单状态码返回订单状态
 * @param status_code
 * @returns {string}
 */
export function unixOrderStatus(status_code) {
  switch (status_code) {
    case 'NEW':
      return '新订单'
    case 'INTODB_ERROR':
      return '入库失败'
    case 'CONFIRM':
      return '已确认'
    case 'PAID_OFF':
      return '已付款'
    case 'SHIPPED':
      return '已发货'
    case 'ROG':
      return '已收货'
    case 'COMPLETE':
      return '已完成'
    case 'CANCELLED':
      return '已取消'
    case 'AFTER_SERVICE':
      return '售后中'
  }
}

/**
 * 格式化货品的规格
 * @param sku
 * @returns {*}
 */
export function formatterSkuSpec(sku) {
  if (!sku.spec_list || !sku.spec_list.length) return ''
  return sku.spec_list.map(spec => spec.spec_value).join(' - ')
}
/**
 * 格式化卡号
 */
export function formatCardNo(cardNo) {
  if (!cardNo) return ''
  if (cardNo.indexOf('ZZ-') > -1) {
    return cardNo.split('-')[2]
  } else {
    return cardNo
  }
}
