/**
 * 用于标签页之间通信的广播类
 */
class TabsBroadcast {
  constructor() {
    this.channel = new BroadcastChannel('app_tabs_channel');
    this.listeners = new Map();
    
    // 监听消息
    this.channel.onmessage = (event) => {
      const { type, data } = event.data;
      this.notifyListeners(type, data);
    };
  }

  /**
   * 发送消息到其他标签页
   * @param {string} type - 消息类型
   * @param {any} data - 消息数据
   */
  postMessage(type, data) {
    this.channel.postMessage({ type, data });
  }

  /**
   * 添加消息监听器
   * @param {string} type - 消息类型
   * @param {Function} callback - 回调函数
   */
  addListener(type, callback) {
    if (!this.listeners.has(type)) {
      this.listeners.set(type, new Set());
    }
    this.listeners.get(type).add(callback);
  }

  /**
   * 移除消息监听器
   * @param {string} type - 消息类型
   * @param {Function} callback - 回调函数
   */
  removeListener(type, callback) {
    if (this.listeners.has(type)) {
      this.listeners.get(type).delete(callback);
    }
  }

  /**
   * 通知所有监听器
   * @param {string} type - 消息类型
   * @param {any} data - 消息数据
   */
  notifyListeners(type, data) {
    if (this.listeners.has(type)) {
      this.listeners.get(type).forEach(callback => callback(data));
    }
  }

  /**
   * 关闭广播通道
   */
  close() {
    this.channel.close();
  }
}

// 创建单例实例
const broadcast = new TabsBroadcast();

// 消息类型常量
export const MESSAGE_TYPES = {
  ACCOUNT_CHANGED: 'ACCOUNT_CHANGED',
  // 可以在这里添加更多消息类型
};

export default broadcast;
