export function checkBrowser () {
  let SystemFlag = {};
  let uaFlag = navigator.userAgent.toLowerCase();
  let uaFlagMatch;
  (uaFlagMatch = uaFlag.match(/edg\/([\d.]+)/))
    ? (SystemFlag.edg = uaFlagMatch[1])
    : (uaFlagMatch = uaFlag.match(/firefox\/([\d.]+)/))
    ? (SystemFlag.firefox = uaFlagMatch[1])
    : (uaFlagMatch = uaFlag.match(/chrome\/([\d.]+)/))
    ? (SystemFlag.chrome = uaFlagMatch[1])
    : (uaFlagMatch = uaFlag.match(/opera.([\d.]+)/))
    ? (SystemFlag.opera = uaFlagMatch[1])
    : (uaFlagMatch = uaFlag.match(/version\/([\d.]+).*safari/))
    ? (SystemFlag.safari = uaFlagMatch[1])
    : 0;  
  const isChrome =  !!SystemFlag.chrome
  
  if (!isChrome) {
    // 如果不是Chrome浏览器，显示提示框并阻止操作
    const message = '为了获得最佳使用体验，请使用 Chrome 浏览器访问本网站。\n' +
                   '点击确定将跳转到Chrome浏览器下载页面。';
    const result = window.confirm(message);
    if (result) {
      // 跳转到Chrome下载页面
      window.location.href = 'https://www.google.cn/chrome/';
    } else {
      // 创建遮罩层阻止操作
      const overlay = document.createElement('div');
      overlay.style.position = 'fixed';
      overlay.style.top = '0';
      overlay.style.left = '0';
      overlay.style.width = '100%';
      overlay.style.height = '100%';
      overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.9)';
      overlay.style.zIndex = '9999';
      overlay.style.display = 'flex';
      overlay.style.flexDirection = 'column';
      overlay.style.justifyContent = 'center';
      overlay.style.alignItems = 'center';
      overlay.style.color = 'white';
      overlay.style.fontSize = '20px';
      
      // 创建提示文本
      const text = document.createElement('div');
      text.innerHTML = '请使用 Chrome 浏览器访问本网站';
      text.style.marginBottom = '20px';
      
      // 创建下载按钮
      const button = document.createElement('button');
      button.innerHTML = '下载 Chrome 浏览器';
      button.style.padding = '10px 20px';
      button.style.fontSize = '16px';
      button.style.backgroundColor = '#4285f4';
      button.style.color = 'white';
      button.style.border = 'none';
      button.style.borderRadius = '4px';
      button.style.cursor = 'pointer';
      button.onclick = () => {
        window.location.href = 'https://www.google.cn/chrome/';
      };
      
      // 添加鼠标悬停效果
      button.onmouseover = () => {
        button.style.backgroundColor = '#3367d6';
      };
      button.onmouseout = () => {
        button.style.backgroundColor = '#4285f4';
      };
      
      overlay.appendChild(text);
      overlay.appendChild(button);
      document.body.appendChild(overlay);
    }
    return false;
  }
  
  return true;
}

export default function checkVersion() {
  const isChrome = checkBrowser();
  if (!isChrome) {
    return false;
  }
  return true;
}