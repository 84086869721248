var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-scrollbar',{attrs:{"wrap-class":"sidenav-scrollbar-wrapper","view-style":_vm.sidenavWrapperStyle}},[_vm._l((_vm.permission_routers),function(route,index){return [(!route.hidden)?_c('div',{staticClass:"menu",class:{ active: _vm.isActive(route) },attrs:{"item":route,"base-path":route.path}},[_c('el-tooltip',{key:route.name,attrs:{"placement":"right","content":route.name
  ? _vm.generateTitle(route.name)
  : _vm.generateTitle(route.children[0].name),"disabled":true,"popper-class":"app-menu-popper"}},[_c('router-link',{attrs:{"to":route.children.length > 0
  ? { name: route.children[0].name }
  : { path: '/' }}},[_c('div',{staticClass:"menuButton",style:(_vm.menuButtonStyle),on:{"click":function($event){return _vm.setRoute(route)}}},[(route.name)?[_c('div',{staticClass:"center"},[(route.meta && route.meta.icon)?_c('img',{style:(_vm.menuButtonIconStyle),attrs:{"src":`/icons/${route.meta.icon}-${_vm.isActive(route) ? 'w' : 'b'
  }.png`,"alt":""}}):_vm._e(),_c('div',{staticClass:"title",style:(_vm.menuButtonTitleStyle)},[_vm._v(" "+_vm._s(_vm.generateTitle(route.name))+" ")])])]:[_c('div',{staticClass:"center"},[(route.children[0].meta && route.children[0].meta.icon)?_c('img',{style:(_vm.menuButtonIconStyle),attrs:{"src":`/icons/${route.meta.icon}-${_vm.isActive(route) ? 'w' : 'b'
  }.png`,"alt":""}}):_vm._e(),_c('div',{staticClass:"title",style:(_vm.menuButtonTitleStyle)},[_vm._v(" "+_vm._s(_vm.generateTitle(route.children[0].name))+" ")])])]],2)])],1),(route.name && !_vm.isNoChild)?_c('div',{staticClass:"child-main-box",style:(_vm.childMainBoxStyle)},[(_vm.permission_routers)?_c('el-scrollbar',{attrs:{"wrap-class":"item-menu-wrapper"}},[_c('el-menu',{attrs:{"default-active":_vm.$route.matched[1].name +
  (_vm.$route.matched[2] ? '-' + _vm.$route.matched[2].name : '')}},[_vm._l((_vm.permission_routers[index].children),function(item,childIndex){return [(!item.hidden)?[(item.children && item.children.length)?_c('el-submenu',{key:childIndex,attrs:{"index":item.name}},[_c('template',{slot:"title"},[_c('div',{style:(_vm.subMenuTitleStyle)},[_vm._v(" "+_vm._s(_vm.generateTitle(item.name))+" ")])]),_vm._l((item.children),function(child,childIndex2){return [(!child.hidden)?_c('router-link',{key:childIndex2,staticClass:"child3",attrs:{"to":{ name: child.name }}},[_c('el-menu-item',{class:{ activated: _vm.isSubItemActive(child) },style:(_vm.subMenuItemStyle),attrs:{"index":item.name + '-' + child.name}},[_c('span',[_vm._v(_vm._s(_vm.generateTitle(child.meta.title)))])])],1):_vm._e()]})],2):_c('router-link',{key:childIndex,attrs:{"to":{ name: item.name }}},[(!_vm.filterRoutes.includes(item.name))?_c('el-menu-item',{class:{ activated: _vm.isSubItemActive(item) },style:(_vm.subMenuItemStyle),attrs:{"index":item.name}},[_c('span',[_vm._v(_vm._s(_vm.generateTitle(item.name)))])]):_vm._e()],1)]:_vm._e()]})],2)],1):_vm._e()],1):_vm._e()],1):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }