/**
 * 特殊顶级域名列表
 * 包含常见的需要特殊处理的域名后缀
 */
const specialTLDs = [
  'com.cn',
  'com.hk',
  'com.tw',
  'co.jp',
  'co.uk',
  'co.kr',
  'co.nz',
  'co.th',
  'net.cn',
  'org.cn',
  'gov.cn'
];

/**
 * 获取主域名（增强版）
 * 支持特殊顶级域名如 .co.uk, .com.cn 等
 * @param {string} urlOrDomain - URL或域名，如 "https://sub.example.co.uk" 或 "sub.example.co.uk"
 * @returns {string} - 返回主域名，如 "example.co.uk"
 * @throws {Error} - 当输入的URL或域名格式无效时抛出错误
 */
export function getMainDomainEnhanced(urlOrDomain) {
  if (!urlOrDomain) return '';
  
  let hostname;
  try {
    // 统一处理：如果不是有效的URL格式，添加https://前缀
    // 这一步同时也会处理 punycode（国际化域名）
    const url = new URL(urlOrDomain.includes('://') ? urlOrDomain : `https://${urlOrDomain}`);
    hostname = url.hostname;
  } catch (error) {
    // 如果URL解析失败，尝试直接处理为域名
    hostname = urlOrDomain
      .replace(/^(.*:\/\/)?(www\.)?/i, '') // 移除协议和www
      .split(':')[0]; // 移除端口号
    
    // 验证域名格式（更严格的规则）
    const domainRegex = /^(?!-)[A-Za-z0-9-]+(?<!-)(\.[A-Za-z0-9](?!-)[A-Za-z0-9-]*(?<!-)){1,}$/;
    if (!domainRegex.test(hostname)) {
      throw new Error('Invalid domain format');
    }
  }

  const parts = hostname.split('.');
  if (parts.length < 2) return hostname;

  // 检查是否包含特殊顶级域名
  const domainSuffix = parts.slice(-2).join('.');
  if (specialTLDs.includes(domainSuffix) && parts.length > 2) {
    return parts.slice(-3).join('.');
  }

  return parts.slice(-2).join('.');
}

/**
 * 获取当前页面的主域名
 * @returns {string} - 返回当前页面的主域名，如 "example.com" 或 "example.co.uk"
 * @throws {Error} - 当在非浏览器环境执行时抛出错误
 */
export function getCurrentMainDomain() {
  if (typeof window === 'undefined' || !window.location) {
    throw new Error('This function can only be used in browser environment');
  }

  try {
    return getMainDomainEnhanced(window.location.hostname);
  } catch (error) {
    throw new Error(`Failed to get current main domain: ${error.message}`);
  }
}
