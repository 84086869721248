/**
 * Created by Andste on 2018/5/28.
 * 商品选择器
 * 依赖于element-ui
 */

import Vue from 'vue'
import GaijiaGiftPicker from './src/main.vue'

GaijiaGiftPicker.install = () => {
  Vue.component(GaijiaGiftPicker.name, GaijiaGiftPicker)
}

export default GaijiaGiftPicker
